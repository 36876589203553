import { Flex, Text } from "@chakra-ui/react";

export interface typeSelected {
  type: "month" | "year";
}

type Props = {
  onSelected: (type: typeSelected) => void;
  typeSelected: typeSelected;
};

export function ButtonSelectTypePlan({ onSelected, typeSelected }: Props) {
  return (
    <Flex>
      <Flex
        width="133px"
        height="45px"
        borderTopStartRadius="10px"
        borderBottomStartRadius="10px"
        borderWidth={1}
        borderColor="#E5E4E4"
        alignItems="center"
        justifyContent="center"
        backgroundColor={
          typeSelected.type === "month" ? "#1E1E1E" : "transparent"
        }
        color={typeSelected.type === "month" ? "white" : "#1E1E1E"}
        cursor="pointer"
        onClick={() => {
          onSelected({
            type: "month",
          });
        }}
      >
        <Text fontSize="18px" fontWeight={500}>
          Mensal
        </Text>
      </Flex>

      <Flex
        width="133px"
        height="45px"
        borderTopEndRadius="10px"
        borderBottomEndRadius="10px"
        borderWidth={1}
        borderColor="#E5E4E4"
        alignItems="center"
        justifyContent="center"
        backgroundColor={
          typeSelected.type === "year" ? "#1E1E1E" : "transparent"
        }
        color={typeSelected.type === "year" ? "white" : "#1E1E1E"}
        cursor="pointer"
        position="relative"
        onClick={() => {
          onSelected({
            type: "year",
          });
        }}
      >
        <Flex
          backgroundColor="#1E1E1E"
          alignItems="center"
          justifyContent="center"
          width="59px"
          height="15px"
          position="absolute"
          borderRadius="20px"
          top={-2}
          left="auto"
        >
          <Text fontSize="10px" color="white" fontWeight={500}>
            25%OFF
          </Text>
        </Flex>
        <Text fontSize="18px" fontWeight={500}>
          Anual
        </Text>
      </Flex>
    </Flex>
  );
}
