export function IconMenuOptionData() {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 3.75c4.142 0 7.5-.84 7.5-1.875C15 .839 11.642 0 7.5 0 3.358 0 0 .84 0 1.875 0 2.911 3.358 3.75 7.5 3.75z"
        fill="#000"
      />
      <path
        d="M7.5 5C3.358 5 0 4.16 0 3.125V7.5c0 1.036 3.358 1.875 7.5 1.875 4.142 0 7.5-.84 7.5-1.875V3.125C15 4.161 11.642 5 7.5 5zM2.187 7.5a.938.938 0 11.001-1.875.938.938 0 010 1.875z"
        fill="#000"
      />
      <path
        d="M7.5 10.625c-4.142 0-7.5-.84-7.5-1.875v4.375C0 14.161 3.358 15 7.5 15c4.142 0 7.5-.84 7.5-1.875V8.75c0 1.036-3.358 1.875-7.5 1.875zm-5.313 2.5a.936.936 0 01-.662-1.601.939.939 0 11.663 1.601z"
        fill="#000"
      />
    </svg>
  );
}
