import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import { BiographyDTO } from "../../../dtos/BiographyDTO";

export function useBiographyShow(id: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<BiographyDTO | null>(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`biography/show/title/${id}`);
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
