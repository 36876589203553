export { default as IMAGE_LOGIN } from "./imagelogin.png";
export { default as LOGO_BRANCA } from "./logobranca.png";
export { default as LOGO } from "./logo.png";
export { default as AULAS } from "./icon/aulas.png";
export { default as CRONOGRAMA } from "./icon/cronograma.png";
export { default as EXTRA } from "./icon/extra.png";
export { default as MENULIST } from "./icon/menulist.png";
export { default as PLAY } from "./icon/play.png";
export { default as QUESTION } from "./icon/question.png";
export { default as QUESTION_BLUE } from "./icon/questionblue.png";
export { default as CRONOGRAMA_BLUE } from "./icon/cronogramablue.png";
