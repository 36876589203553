import { Box, Flex, Icon, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { ButtonBaseInformationAdd } from "../../../components/buttonBaseInformationAdd";
import { FileRejection, useDropzone } from "react-dropzone";
import { api } from "../../../../../../service/api";
import { useEffect, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useListBaseInformations } from "../../../hooks/useListBaseInformations";
import { BoxLoading } from "../../../../../../components/boxLoading";
import { AudioPlay } from "../audioPlay";
import { ModalPageLoadingAiNext } from "../../../../../../components/modalPageLoadingAiNext";
import { ModalDelete } from "../../../../../../components/modalDelete";

const LOCALITY = "audios";

type Props = {
  id: string;
  type: string;
};

export function IntroductionAudio({ id, type }: Props) {
  const { dataList, loading, refetch } = useListBaseInformations();

  const [loadingSend, setLoadingSend] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [idCard, setIdCard] = useState("");
  const { open } = useDropzone({
    noKeyboard: true,
    maxFiles: 0,
    noClick: true,
    multiple: false,
    accept: {
      "audio/mpeg": [".mp3"],
      "audio/wav": [".wav"],
      "audio/webm": [".webm"],
      "audio/flac": [".flac"],
      "audio/x-m4a": [".m4a"],
    },
    minSize: 0,
    maxSize: 10242880,
    onDrop: (files, fileRejections) => sendDocuments(files[0], fileRejections),
  });

  async function sendDocuments(file: File, fileRejections: FileRejection[]) {
    try {
      if (fileRejections.length > 0) {
        if (fileRejections[0].errors[0].code === "file-too-large") {
          setErrorTitle("Arquivo maior que de 10MB não é permitido.");
        } else {
          setErrorTitle("Não foi possível continuar com arquivo.");
        }
        setIsError(true);
        return;
      }

      setLoadingSend(true);
      console.log(file);
      const dataDocument = new FormData();
      dataDocument.append("biography_id", id);
      dataDocument.append("type", type);
      dataDocument.append("locality", LOCALITY);
      dataDocument.append("path", file);
      dataDocument.append("title", file.name);
      dataDocument.append("description", file.name);
      await api.post("baseinformation/create", dataDocument);
      getData();
    } catch (error) {
    } finally {
      setLoadingSend(false);
    }
  }

  async function deleteData(id: string) {
    try {
      setIdCard(id);
      setIsOpenDelete(true);
    } catch (error) {}
  }

  function getData() {
    refetch({
      id,
      type,
      locality: LOCALITY,
    });
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  return (
    <>
      <ModalDelete
        is_open={isOpenDelete}
        api_url={`baseinformation/delete/${idCard}`}
        title="Deletar áudio"
        description="Deseja deletar o áudio?"
        onClose={() => {
          setIsOpenDelete(false);
          setIdCard("");
        }}
        onCloseSend={() => {
          setIsOpenDelete(false);
          getData();
          setIdCard("");
        }}
      />
      <ModalPageLoadingAiNext
        is_open={isError}
        title={errorTitle}
        statusModal="alert"
        titleBtn="Ok"
        onRefresh={() => {
          setIsError(false);
        }}
      />

      <Flex flexDirection="column" mt="5px">
        {loading && <BoxLoading />}
        <Box>
          <ButtonBaseInformationAdd
            isLoading={loadingSend}
            title="Novo arquivo de áudio"
            onPress={() => {
              open();
            }}
          />
        </Box>
        <Flex mt="25px" width="100%">
          <Wrap spacing="30px" width="100%">
            {!loading &&
              dataList &&
              dataList.map((item, _) => (
                <WrapItem key={item.id} width="100%">
                  <Flex
                    width="100%"
                    borderWidth="1px"
                    borderColor="#E5E4E4"
                    borderRadius="10px"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    gap="10px"
                    cursor="pointer"
                    onMouseOver={() => {
                      setSelectedItem(item.id);
                    }}
                    onMouseOut={() => {
                      setSelectedItem("");
                    }}
                    position="relative"
                  >
                    <AudioPlay data={item} />
                    {selectedItem === item.id && (
                      <Flex
                        position="absolute"
                        bottom="0px"
                        gap="5px"
                        cursor="pointer"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {
                          deleteData(item.id);
                        }}
                      >
                        <Icon
                          as={RiDeleteBin5Line}
                          fontSize="14px"
                          color="#FF5656"
                        />
                        <Text color="#FF5656" fontWeight="bold">
                          Excluir
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </WrapItem>
              ))}
          </Wrap>
        </Flex>
      </Flex>
    </>
  );
}
