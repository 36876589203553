import {
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { LOGO } from "../../assets";
import {
  RiFlashlightFill,
  RiHome2Line,
  RiLogoutBoxLine,
  RiMenuLine,
} from "react-icons/ri";
import { useAuth } from "../../hooks/useAuth";
import { ButtonStatusSubscription } from "../ButtonStatusSubscription";
import { ButtonStatusCredits } from "../ButtonStatusCredits";

const linksMenu = [
  {
    icon: "icon",
    link: "/home",
    path: "home",
    title: "Meus Livros",
    index: 0,
    type: "admin|user",
  },
  {
    icon: "icon",
    link: "/minha-conta",
    path: "accountme",
    title: "Minha conta",
    type: "admin|user",
    index: 2,
  },
  {
    icon: "icon",
    link: "/configuracoes",
    path: "configuracoes",
    title: "Configurações",
    type: "admin|user|user",
    index: 3,
  },
];

type Props = {
  title?: string;
  subTitle?: string;
  subPath?: string;
  path?: string;
};

export function Header({ title, subTitle, path, subPath }: Props) {
  const { logout, user } = useAuth();

  return (
    <Flex flexDirection="column">
      <Flex width="100%" height="34px" backgroundColor="#D9D9D9" />
      <Flex
        style={{
          height: 70,
          borderBottomWidth: 1,
          borderBottomColor: "#EBEBEB",
          width: "100%",
          paddingLeft: 50,
          alignItems: "center",
        }}
        paddingRight={["10px", "10px", "50px"]}
      >
        <Flex
          ml="10px"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Image
            src={LOGO}
            style={{ width: "180px", height: "30px" }}
            alt="logo"
          />

          <Flex
            display={["none", "none", "flex"]}
            style={{
              margin: "auto",
              width: "100%",
              flex: 1,
            }}
            justifyContent="center"
            alignItems="center"
          >
            {linksMenu
              .filter((data) => data.type.includes(user.type))
              .map((item, index) => (
                <Link
                  href={item.link}
                  style={{
                    marginRight: 30,
                    color: "#000000",
                    fontFamily: "Kanit",
                    opacity: path === item.path ? 1 : 0.3,
                  }}
                  key={index}
                >
                  {item.title}
                </Link>
              ))}
          </Flex>
          {/* user.subscription
                    ? user.subscription.balance_credits.toString()
                    : "" */}
          <Flex gap="40px">
            <Flex
              alignItems="center"
              justifyItems="center"
              display={["none", "none", "flex"]}
              gap={5}
            >
              <ButtonStatusCredits title="Páginas:" subtitle={"50"} />
              <ButtonStatusSubscription
                title="Upgrade"
                icon={
                  <Icon fontSize="18px" as={RiFlashlightFill} color="white" />
                }
                link="/planos"
              />
            </Flex>

            <Flex
              display={["none", "none", "flex"]}
              style={{ cursor: "pointer" }}
              alignItems="center"
              onClick={() => {
                logout();
              }}
            >
              <Icon fontSize="16px" as={RiLogoutBoxLine} color="blue.400" />
              <Text
                color="blue.400"
                style={{
                  fontWeight: 500,
                  marginLeft: 2,
                  fontFamily: "Kanit",
                }}
              >
                Sair
              </Text>
            </Flex>
          </Flex>

          {/* <Flex display={["none", "none", "flex"]} alignItems="center">
            <Icon fontSize="16px" as={RiMenuLine} color="#B6A790" />
            <Text
              style={{
                color: "#B6A790",
                fontWeight: 500,
                marginLeft: 2,
                fontFamily: "Kanit",
              }}
            >
              Menu
            </Text>
          </Flex> */}

          <Flex display={["flex", "flex", "none"]}>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<Icon fontSize="16px" as={RiMenuLine} color="blue.400" />}
                variant="outline"
                _hover={{ backgroundColor: "blue.100" }}
                _active={{ backgroundColor: "blue.100" }}
              />
              <MenuList>
                {linksMenu
                  .filter((data) => data.type.includes(user.type))
                  .map((item, index) => (
                    <Link href={item.link}>
                      <MenuItem
                        key={index}
                        _hover={{ backgroundColor: "blue.400" }}
                        _active={{ backgroundColor: "blue.100" }}
                        _focus={{ backgroundColor: "blue.100" }}
                      >
                        <Text
                          style={{
                            color: "black",
                            fontFamily: "Kanit",
                          }}
                        >
                          {item.title}
                        </Text>
                      </MenuItem>
                    </Link>
                  ))}

                <MenuItem>
                  <Flex
                    style={{ cursor: "pointer" }}
                    alignItems="center"
                    onClick={() => {
                      logout();
                    }}
                  >
                    <Icon
                      fontSize="16px"
                      as={RiLogoutBoxLine}
                      color="blue.400"
                    />
                    <Text
                      style={{
                        color: "blue.400",
                        fontWeight: 500,
                        marginLeft: 2,
                        fontFamily: "Kanit",
                      }}
                    >
                      Sair
                    </Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      {path && title && (
        <Flex
          style={{
            width: "100%",
            paddingLeft: 250,
            paddingRight: 250,
            marginTop: 26,
            alignItems: "center",
          }}
        >
          <Link href={path}>
            <Flex style={{ alignItems: "center" }}>
              <Icon fontSize="14px" as={RiHome2Line} color="#6F6F6F" />
              <Text
                style={{
                  color: "#6F6F6F",
                  fontSize: 12,
                  fontFamily: "Kanit",
                  marginLeft: 2,
                }}
              >
                {title}
              </Text>
            </Flex>
          </Link>

          {subTitle && (
            <>
              <Text
                style={{
                  color: "#6F6F6F",
                  fontSize: 12,
                  fontFamily: "Kanit",
                  marginLeft: 9,
                  marginRight: 9,
                }}
              >
                {">"}
              </Text>
              <Link href={subPath}>
                <Text
                  style={{
                    color: "#B6A790",
                    fontSize: 12,
                    fontFamily: "Kanit",
                  }}
                >
                  {subTitle}
                </Text>
              </Link>
            </>
          )}
        </Flex>
      )}

      {path === "home" && (
        <Flex
          alignItems="center"
          justifyItems="center"
          display={["flex", "flex", "none"]}
          gap={5}
          flexDirection="column"
          mt="20px"
          px="20px"
        >
          <ButtonStatusCredits title="Páginas:" subtitle={"50"} />
          <ButtonStatusSubscription
            title="Upgrade"
            icon={<Icon fontSize="18px" as={RiFlashlightFill} color="white" />}
            link="/planos"
          />
        </Flex>
      )}
    </Flex>
  );
}
