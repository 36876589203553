export function IconUploadBaseInformation() {
  return (
    <svg
      width={27}
      height={19}
      viewBox="0 0 27 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 10.909c0 2.935-2.693 5.323-6.002 5.323h-3.385c-.466 0-.843-.335-.843-.748 0-.414.377-.749.843-.749h3.385c2.379 0 4.314-1.716 4.314-3.826 0-2.11-1.935-3.827-4.314-3.827h-1.2c-.466 0-.844-.335-.844-.748 0-2.667-2.447-4.837-5.454-4.837-3.008 0-5.454 2.17-5.454 4.837 0 .413-.378.748-.844.748h-1.2c-2.379 0-4.314 1.717-4.314 3.827s1.935 3.826 4.314 3.826h3.385c.466 0 .843.335.843.749 0 .413-.377.748-.843.748H6.002C2.692 16.232 0 13.844 0 10.909c0-2.935 2.693-5.323 6.002-5.323h.406C6.826 2.444 9.848 0 13.5 0c3.653 0 6.674 2.444 7.092 5.586h.406c3.31 0 6.002 2.388 6.002 5.323zm-10.204.783l-2.7-2.394a.922.922 0 00-1.193 0l-2.699 2.394a.69.69 0 000 1.059.921.921 0 001.193 0l1.26-1.117v6.618c0 .413.377.748.843.748.466 0 .844-.335.844-.748v-6.618l1.259 1.117c.165.146.38.219.596.219a.9.9 0 00.597-.22.69.69 0 000-1.058z"
        fill="#fff"
      />
    </svg>
  );
}
