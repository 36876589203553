import { PlanDTO } from "../dtos/PlanDTO";

export const plansFree: PlanDTO[] = [
  {
    id: "empty",
    st_plan_id: "empty",
    st_plan_price_id: "empty",
    name: "Plano livro teste",
    description: "Plano livro teste description",
    type: "month",
    unit_amount: 0,
    credits: 1300,
    created_at: "2024-09-01T01:32:49.000000Z",
    plan_items: [
      {
        id: "04cf3daa-7ea2-4583-a006-f2c734eb3ab8",
        plan_id: "9f3490f4-6596-4970-bb15-bf4d88731779",
        name: "Detalhe plano livro teste description",
        created_at: "2024-09-01T01:32:49.000000Z",
      },
      {
        id: "2d1139f2-8d22-4b8e-bb64-efaeabca2c7d",
        plan_id: "9f3490f4-6596-4970-bb15-bf4d88731779",
        name: "Detalhe plano livro teste description 1",
        created_at: "2024-09-01T01:32:49.000000Z",
      },
      {
        id: "29e012f2-c32c-4df3-89fa-7f5b1110ac9f",
        plan_id: "9f3490f4-6596-4970-bb15-bf4d88731779",
        name: "Detalhe plano livro teste description 2",
        created_at: "2024-09-01T01:32:49.000000Z",
      },
    ],
  },
];

export const typesBaseInformation = [
  {
    id: 0,
    title: "Infância",
    type: "infancy",
    locality: "infancy",
    description: "infancy",
    buttonTitle: "infancy",
  },
  {
    id: 2,
    title: "Família",
    type: "family",
    locality: "family",
    description: "family",
    buttonTitle: "family",
  },
  {
    id: 3,
    title: "Memórias da escola e do Ensino Médio",
    type: "memories-of-school-and-high-school",
    locality: "memories-of-school-and-high-school",
    description: "memories-of-school-and-high-school",
    buttonTitle: "memories-of-school-and-high-school",
  },
  {
    id: 4,
    title: "Faculdade e o início da vida adulta",
    type: "college-and-early-adulthood",
    locality: "college-and-early-adulthood",
    description: "college-and-early-adulthood",
    buttonTitle: "college-and-early-adulthood",
  },
  // {
  //   id: 5,
  //   title: "Carreira e empreendedorismo",
  //   type: "career-and-entrepreneurship",
  //   locality: "career-and-entrepreneurship",
  //   description: "career-and-entrepreneurship",
  //   buttonTitle: "career-and-entrepreneurship",
  // },
  {
    id: 6,
    title: "Carreira",
    type: "career",
    locality: "career",
    description: "career",
    buttonTitle: "career",
  },
  {
    id: 7,
    title: "Vida afetiva",
    type: "love-life",
    locality: "love-life",
    description: "love-life",
    buttonTitle: "love-life",
  },
  {
    id: 8,
    title: "Causas políticas e sociais",
    type: "political-and-social-causes",
    locality: "political-and-social-causes",
    description: "political-and-social-causes",
    buttonTitle: "political-and-social-causes",
  },
  {
    id: 9,
    title: "Temas gerais",
    type: "general-topics",
    locality: "general-topics",
    description: "general-topics",
    buttonTitle: "general-topics",
  },
  // },
  // {
  //   id: 10,
  //   title: "Entrevistas e Depoimentos",
  //   type: "interviews-and-testimonials",
  //   locality: "interviews-and-testimonials",
  //   description: "interviews-and-testimonials",
  //   buttonTitle: "interviews-and-testimonials",
  // },
  // {
  //   id: 11,
  //   title: "Parceiros que construíram juntos",
  //   type: "partners-who-built-together",
  //   locality: "partners-who-built-together",
  //   description: "partners-who-built-together",
  //   buttonTitle: "partners-who-built-together",
  // },
  // {
  //   id: 12,
  //   title: "Eventos importantes durante a vida",
  //   type: "important-events-during-life",
  //   locality: "important-events-during-life",
  //   description: "important-events-during-life",
  //   buttonTitle: "important-events-during-life",
  // },
  // {
  //   id: 13,
  //   title: "Grandes feitos",
  //   type: "great-achievements",
  //   locality: "great-achievements",
  //   description: "great-achievements",
  //   buttonTitle: "great-achievements",
  // },
  // {
  //   id: 14,
  //   title: "Histórias engraçadas",
  //   type: "funny-stories",
  //   locality: "funny-stories",
  //   description: "funny-stories",
  //   buttonTitle: "funny-stories",
  // },
  // {
  //   id: 15,
  //   title: "Histórias dramáticas de amigos",
  //   type: "dramatic-stories-of-friends",
  //   locality: "dramatic-stories-of-friends",
  //   description: "dramatic-stories-of-friends",
  //   buttonTitle: "dramatic-stories-of-friends",
  // },
  // {
  //   id: 16,
  //   title: "Histórias de familiares",
  //   type: "family-stories",
  //   locality: "family-stories",
  //   description: "family-stories",
  //   buttonTitle: "family-stories",
  // },
  // {
  //   id: 17,
  //   title: "Missão de vida",
  //   type: "life-mission",
  //   locality: "life-mission",
  //   description: "life-mission",
  //   buttonTitle: "life-mission",
  // },
  // {
  //   id: 18,
  //   title: "Como se lembram dessa pessoa",
  //   type: "how-do-you-remember-this-person",
  //   locality: "how-do-you-remember-this-person",
  //   description: "how-do-you-remember-this-person",
  //   buttonTitle: "how-do-you-remember-this-person",
  // },
  // {
  //   id: 19,
  //   title: "Legados de vida",
  //   type: "life-legacies",
  //   locality: "life-legacies",
  //   description: "life-legacies",
  //   buttonTitle: "life-legacies",
  // },
];

export interface ICopyData {
  id: string;
  quantityCopy: number;
  price: number;
  delivery_time: number;
}

export const copyData: ICopyData[] = [
  {
    id: "1",
    quantityCopy: 100,
    price: 90,
    delivery_time: 90,
  },
  {
    id: "2",
    quantityCopy: 200,
    price: 160,
    delivery_time: 90,
  },
  {
    id: "3",
    quantityCopy: 400,
    price: 320,
    delivery_time: 90,
  },
];
