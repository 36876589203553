import { Flex } from "@chakra-ui/react";
import { IntroductionTextContent } from "./introductionTextContent";
import { useEffect } from "react";
import { useListBaseInformations } from "../../../hooks/useListBaseInformations";
import { dataQuestions } from "../../../../../../data/data_questions";
import { AllLoading } from "../../../../../../components/allLoading";

type Props = {
  id: string;
  type: string;
};

export function IntroductionText({ id, type }: Props) {
  const { dataList, loading, refetch } = useListBaseInformations();

  useEffect(() => {
    refetch({
      id,
      type,
      locality: "text",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  return (
    <Flex flexDirection="column" mt="5px">
      {loading && <AllLoading />}
      <Flex mt="25px" gap="15px" flexDirection="column">
        {dataQuestions
          .find((value) => value.type === type)
          ?.questions.map((item, _) => (
            <IntroductionTextContent
              id={item.id}
              biography_id={id}
              type={type}
              key={item.id}
              data={dataList.find((list) => list.id_ref === item.id) ?? null}
              description={item.description}
              onCloseSend={() => {
                refetch({
                  id,
                  type,
                  locality: "text",
                });
              }}
            />
          ))}
      </Flex>
    </Flex>
  );
}
