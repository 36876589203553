export function IconDocumentsBaseInformation() {
  return (
    <svg
      width={12}
      height={15}
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.865 7.751H3.187a.415.415 0 100 .831h5.678a.415.415 0 100-.831zm-5.73-1.487h5.678a.415.415 0 100-.832H3.135a.415.415 0 100 .832zm5.73 3.803H3.187a.415.415 0 100 .831h5.678a.415.415 0 100-.831z"
        fill="#36A9E1"
      />
      <path
        d="M11.87 3.4L8.432.117A.425.425 0 008.14 0H1.85C.83 0 0 .825 0 1.838v11.324C0 14.175.83 15 1.85 15h8.3c1.02 0 1.85-.825 1.85-1.838v-9.46a.442.442 0 00-.13-.302zM8.562 1.392l1.979 1.894h-.97A1.01 1.01 0 018.56 2.279v-.887h.003zm2.599 11.77a1.01 1.01 0 01-1.013 1.006H1.849a1.01 1.01 0 01-1.013-1.006V1.835A1.01 1.01 0 011.85.828h5.873v1.448c0 1.013.83 1.838 1.85 1.838h1.589v9.048z"
        fill="#36A9E1"
      />
    </svg>
  );
}
