import { Flex, Text } from "@chakra-ui/react";
import { numberFormart } from "../../utils/formatters";

type Props = {
  title: string;
  subtitle: string;
};

export function ButtonStatusCredits({ title, subtitle }: Props) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      backgroundColor="#FFFFFF"
      boxShadow="base"
      px="20px"
      height="43px"
      borderRadius="50px"
      width={["100%", "100%", "auto"]}
      gap={1}
    >
      <Text style={{ fontWeight: 500, fontSize: 12, color: "#1E1E1E" }}>
        {title}
      </Text>

      <Text style={{ fontWeight: 600, fontSize: 12, color: "#42AD4D" }}>
        {numberFormart(subtitle)}
      </Text>
    </Flex>
  );
}
