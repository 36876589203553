import { Box, Flex } from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import { BiographySidebar } from "../components/sidebar";
import { BaseInformationHeader } from "./components/bookDataHeader";
import { BookDataMenu } from "./components/bookDataMenu";
import { PageData } from "./components/pageData";
import { PageCover } from "./components/pageCover";
import { PageSummaryOrSynopsis } from "./components/pageSummaryOrSynopsis";
import { TitleForm } from "./components/titleForm";

export function BookData() {
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const pageType = searchParams.get("type") ?? "";
  const pageChapter = searchParams.get("chapter") ?? "";
  const pageId = searchParams.get("page") ?? "";

  return (
    <Flex width="100%">
      <BiographySidebar id={id as string} type="book" />
      <Box
        width="100%"
        mt={["60px", "40px", "0px"]}
        px={["0px", "0px", "40px"]}
        py={["0px", "0px", "60px"]}
      >
        <BaseInformationHeader />
        <Flex mt="40px" gap="30px">
          <BookDataMenu
            id={id as string}
            pageType={pageType}
            pageChapter={pageChapter}
            pageId={pageId}
          />
          <Flex width="100%">
            {pageType === "cover" && (
              <PageCover id={id as string} pageType={pageType} />
            )}
            {pageType === "synopsis" && (
              <PageSummaryOrSynopsis
                id={id as string}
                pageType={pageType}
                title="Sinopse"
                titlleButtom="Gerar sinopse"
              />
            )}
            {pageType === "summary" && (
              <PageSummaryOrSynopsis
                id={id as string}
                pageType={pageType}
                title="summary"
                titlleButtom="Gerar sumário"
              />
            )}
            {pageType === "title_ebook" && (
              <TitleForm id={id as string} pageType={pageType} />
            )}
            {pageType === "chapter" && (
              <PageData
                page_id={pageId}
                chapter_id={pageChapter}
                id={id as string}
              />
            )}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
