import { Flex, Icon, Text } from "@chakra-ui/react";
import { IconSelectBox } from "../IconSelectBox";
import { SelectBoxProps } from "./SelectBox.types";

export function BiographySelectBox({
  onPress,
  onPressNewBook,
  title,
}: SelectBoxProps) {
  return (
    <Flex flexDirection="column" width="250px">
      <Text fontWeight={500} fontSize="14px" color="#1E1E1E">
        Biografia de
      </Text>
      <Flex
        mt="8px"
        borderWidth={2}
        borderRadius="10px"
        px="15px"
        py="10px"
        borderColor="#DDDDDD"
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        onClick={onPress}
      >
        <Text fontWeight={600} fontSize="14px" color="#3A3A3A">
          {title}
        </Text>
        <Icon fontSize="18px" as={IconSelectBox} color="#3A3A3A" />
      </Flex>

      <Flex mt="20px" cursor="pointer" onClick={onPressNewBook}>
        <Text fontWeight={500} fontSize="14px" color="blue.400">
          + criar nova biografia
        </Text>
      </Flex>
    </Flex>
  );
}
