import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./routes";
import { themeChakra } from "./styles/theme-chakra";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./contexts/AuthContext";
import { BiographyProvider } from "./hooks/contexts/biography/biography.provider";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={themeChakra}>
        <AuthProvider>
          <BrowserRouter>
            <BiographyProvider>
              <AppRouter />
            </BiographyProvider>
          </BrowserRouter>
        </AuthProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
