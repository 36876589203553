export function numberFormart(num: number | string) {
  const newNumber = Number(num);
  return new Intl.NumberFormat("pt-BR").format(newNumber);
}

export function dateTimerToDate(timer: string) {
  if (!timer) {
    return "";
  }
  const options = {
    timeZone: "UTC",
  };
  return new Intl.DateTimeFormat("pt-BR", options).format(
    new Date(Number(timer) * 1000)
  );
}

export function millisToMinutesAndSeconds(millis: number) {
  var sec = Math.floor(millis / 1000);
  var hrs = Math.floor(sec / 3600);
  sec -= hrs * 3600;
  var min = Math.floor(sec / 60);
  sec -= min * 60;

  let neWsec = "" + sec;
  neWsec = ("00" + sec).substring(neWsec.length);

  if (hrs > 0) {
    let newMin = "" + min;
    newMin = ("00" + min).substring(newMin.length);
    return hrs + ":" + newMin + ":" + neWsec;
  } else {
    return min + ":" + neWsec;
  }
}
