import {
  ButtonGroup,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Input,
  useEditableControls,
  useToast,
} from "@chakra-ui/react";
import { summaryDetail } from "../../../../../dtos/BookDataDTO";
import { RiArrowGoBackFill, RiCheckFill, RiEdit2Fill } from "react-icons/ri";
import { useState } from "react";
import { api } from "../../../../../service/api";
import { AllLoading } from "../../../../../components/allLoading";
import { useBiographyContext } from "../../../../../hooks/contexts/biography/biography.context";

type Props = {
  data: summaryDetail;
  biography_id: string;
};
export function SummaryDetail({ data, biography_id }: Props) {
  const [textValue, setTextValue] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const { getMenuBook } = useBiographyContext();

  const toast = useToast();

  // async function deleteTitle() {
  //   try {
  //     setLoading(true);

  //     await api.delete(`book/chapter/delete/${data.id}`);
  //     toast({
  //       position: "top-right",
  //       title: "Meu Biógrafo",
  //       description: "Titulo removido com sucesso.",
  //       status: "success",
  //       duration: 9000,
  //       isClosable: true,
  //     });

  //     window.location.reload();
  //   } catch (error) {
  //     toast({
  //       position: "top-right",
  //       title: "Meu Biógrafo",
  //       description: "Não foi possível finalizar.",
  //       status: "error",
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          aria-label="Salvar"
          colorScheme="blue"
          icon={<RiCheckFill />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          aria-label="Cancelar"
          colorScheme="blue"
          icon={<RiArrowGoBackFill />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          size="sm"
          aria-label="Editar"
          colorScheme="blue"
          icon={<RiEdit2Fill />}
          {...getEditButtonProps()}
        />
        {/* <IconButton
          aria-label="excluir"
          colorScheme="blue"
          variant="outline"
          icon={<RiCloseFill />}
          onClick={() => {
            deleteTitle();
          }}
        /> */}
      </ButtonGroup>
    );
  }

  async function sendTitleSumary() {
    try {
      setLoading(true);

      if (textValue === "") {
        window.location.reload();
        return;
      }

      await api.put(`book/chapter/update/${data.id}`, {
        title: textValue,
        content: textValue,
      });
      toast({
        position: "top-right",
        title: "Meu Biógrafo",
        description: "Titulo atualizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      getMenuBook({ id: biography_id, category_name: "chapter" });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Meu Biógrafo",
        description: "Não foi possível finalizar.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <AllLoading />}
      <Editable
        textAlign="center"
        defaultValue={data.title}
        fontSize="2xl"
        isPreviewFocusable={false}
        onChange={(newValue) => {
          setTextValue(String(newValue));
        }}
        onSubmit={() => {
          sendTitleSumary();
        }}
      >
        <Flex gap="20px">
          <Flex flex={1}>
            <EditablePreview />
            <Input as={EditableInput} />
          </Flex>
          <EditableControls />
        </Flex>
      </Editable>
    </>
  );
}
