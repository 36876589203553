import { useRef } from "react";
import { Editor } from "@tiptap/react";
import {
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Bold,
  Heading1,
  Heading2,
  ImageUp,
  Italic,
  List,
  ListOrdered,
  Redo,
  Strikethrough,
  Undo,
} from "lucide-react";
import { Flex } from "@chakra-ui/react";

const EditorMenuBar = ({ editor }: { editor: Editor }) => {
  const inputFileRef = useRef<any>(null);
  return (
    <Flex>
      <div className="flex flex-wrap gap-2">
        <input
          type="color"
          onChange={(event) => {
            editor.chain().focus().setColor(event.target.value).run();
          }}
          value={editor.getAttributes("textStyle").color}
          data-testid="setColor"
        />
        <button
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          className={editor.isActive({ textAlign: "left" }) ? "is-active" : ""}
        >
          <AlignLeft className="w-6 h-6" />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          className={
            editor.isActive({ textAlign: "center" }) ? "is-active" : ""
          }
        >
          <AlignCenter className="w-6 h-6" />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
        >
          <AlignRight className="w-6 h-6" />
        </button>
        <button
          onClick={() => editor.chain().focus().setTextAlign("justify").run()}
          className={
            editor.isActive({ textAlign: "justify" }) ? "is-active" : ""
          }
        >
          <AlignJustify className="w-6 h-6" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
        >
          <Bold className="w-6 h-6" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
        >
          <Italic className="w-6 h-6" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is-active" : ""}
        >
          <Strikethrough className="w-6 h-6" />
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
          className={
            editor.isActive("heading", { level: 1 }) ? "is-active" : ""
          }
        >
          <Heading1 className="w-7 h-7" />
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive("heading", { level: 2 }) ? "is-active" : ""
          }
        >
          <Heading2 className="w-7 h-7" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
        >
          <List className="w-6 h-6" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is-active" : ""}
        >
          <ListOrdered className="w-6 h-6" />
        </button>
        <button
          style={{ display: "none" }}
          onClick={(event) => {
            event.preventDefault();
            var input = inputFileRef.current;
            input.click();
            input.onchange = (event: any) => {
              var file = event.target.files[0];
              if (!file) return;

              const reader = new FileReader();

              reader.onload = (e: any) => {
                const dataURL = e.target.result;

                if (dataURL) {
                  editor
                    .chain()
                    .focus()
                    .setImage({ src: dataURL, title: "imagem" })
                    .run();
                  // editor.commands.insertContent(`<img src="${dataURL}" />`)
                }
              };

              reader.readAsDataURL(file);

              // var file = e.target.files[0];
              // const url = URL.createObjectURL(file);
              // if (url) {
              //   editor.commands.insertContent(`<img src="${url}" />`);
              // }
            };
          }}
        >
          <ImageUp className="w-6 h-6" />
        </button>

        <input
          id="fileUpload"
          ref={inputFileRef}
          type="file"
          style={{ display: "none" }}
        />
        <button
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <Undo className="w-6 h-6" />
        </button>
        <button
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <Redo className="w-6 h-6" />
        </button>
      </div>
    </Flex>
  );
};

export default EditorMenuBar;
