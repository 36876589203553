export function IconPdfAudio() {
  return (
    <svg
      width={30}
      height={33}
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.22 14.875L19.737 11h-.986a2.399 2.399 0 01-2.386-2.406V6.531a2.399 2.399 0 012.386-2.406h.986L24.22.249a1.019 1.019 0 011.526.226c.106.166.162.359.162.556v13.062a1.037 1.037 0 01-.595.936 1.016 1.016 0 01-1.093-.154zm3.357-4.516a1.039 1.039 0 01-.01-1.46c.192-.196.387-.685.387-1.337 0-.652-.195-1.141-.387-1.338a1.038 1.038 0 01.01-1.46 1.023 1.023 0 011.448.01c.62.636.975 1.651.975 2.788 0 1.137-.356 2.152-.976 2.787a1.021 1.021 0 01-1.114.231 1.023 1.023 0 01-.333-.221z"
        fill="#36A9E1"
      />
      <path
        d="M24.546 17.86a3.75 3.75 0 01-2.1-.894l-3.723-3.217a5.098 5.098 0 01-3.597-1.521 5.183 5.183 0 01-1.49-3.635V6.531c0-2.832 2.278-5.142 5.087-5.156L20.318 0H4.091C1.84 0 0 1.856 0 4.125V24.06c0 1.347 1.826 3.437 4.09 3.437h4.092v4.469c0 .896 1.06 1.36 1.704.77l2.387-2.145 2.386 2.145c.62.578 1.705.15 1.705-.77v-4.469h6.818c.75 0 1.364-.619 1.364-1.375V17.86zM8.181 24.748H4.09c-.75 0-1.364-.618-1.364-1.375 0-.756.614-1.374 1.364-1.374h4.09v2.75zm13.636 0h-5.454v-2.75h5.454v2.75z"
        fill="#36A9E1"
      />
    </svg>
  );
}
