import { Box, Flex } from "@chakra-ui/react";

import { useParams } from "react-router-dom";
import { BiographySidebar } from "../components/sidebar";
import { ChatMessage } from "./components/chatMessage";

export function BiographyChatbot() {
  let { id } = useParams();
  return (
    <Flex width="100%">
      <BiographySidebar id={id as string} type="biography" />
      <Box width="100%">
        <ChatMessage id={id as string} />
      </Box>
    </Flex>
  );
}
