import "./styles.scss";
import React, { useState } from "react";
import {
  EditorContent,
  useEditor,
  BubbleMenu,
  FloatingMenu,
} from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";
import { Document } from "@tiptap/extension-document";
import { TextAlign } from "@tiptap/extension-text-align";
import { FontFamily } from "@tiptap/extension-font-family";
import { Paragraph } from "@tiptap/extension-paragraph";
import { TextStyle } from "@tiptap/extension-text-style";
import { Strike } from "@tiptap/extension-strike";
import { Text } from "@tiptap/extension-text";
import { Heading } from "@tiptap/extension-heading";
import { Color } from "@tiptap/extension-color";
import Image from "@tiptap/extension-image";
import Dropcursor from "@tiptap/extension-dropcursor";
import TipTapMenuBar from "../editorMenuBar";
import { Flex, Button, Icon, Spinner } from "@chakra-ui/react";
import { mergeAttributes } from "@tiptap/react";

import { Bot, BotMessageSquare, Check, CheckCheck } from "lucide-react";
import { PopoverIa } from "../popoverIa";
import { useDebounce } from "../../utils/useDebounce";
import { api } from "../../service/api";
import { ModalPageLoadingAiNext } from "../modalPageLoadingAiNext";

type Props = {
  content: string;
  isLoading: boolean;
  isLoadingSave: boolean;
  onSave: (text: string) => void;
  onFinalize: () => void;
  onRewrite: () => void;
  urlApi?: string | null;
  dataApi?: object | null;
  isRewrite?: boolean;
};

const EditorV2 = ({
  content,
  onFinalize,
  onRewrite,
  onSave,
  isLoading,
  isLoadingSave,
  isRewrite = false,
  urlApi = null,
  dataApi = null,
}: Props) => {
  const [editorState, setEditorState] = React.useState(content);

  const [loadingIaSend, setLoadingIaSend] = useState(false);

  const editor = useEditor({
    autofocus: true,
    extensions: [
      StarterKit,
      Document,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      FontFamily,
      Paragraph,
      TextStyle,
      Strike,
      Text,
      Heading,
      Color,
      Image.extend({
        defaultOptions: {
          ...Image.options,
          sizes: ["inline", "block", "left", "right"],
          allowBase64: true,
        },
        renderHTML({ HTMLAttributes }) {
          const { style } = HTMLAttributes;
          return [
            "figure",
            { style },
            [
              "img",
              mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
            ],
          ];
        },
      }),
      Dropcursor,
    ],
    content: editorState,
    onUpdate: ({ editor }) => {
      setEditorState(editor.getHTML());
    },
    onDrop: ({ view }) => {
      return false;
    },
  });

  async function genereteText(type: "replace" | "continue") {
    try {
      setLoadingIaSend(true);
      if (editor && urlApi && dataApi) {
        const { from, to } = editor.view.state.selection;
        const text = editor.state.doc.textBetween(from, to);

        const values = {
          ...dataApi,
          value: text,
          contentValue: editorState,
          type,
        };

        const response = await api.put(urlApi, { ...values });
        const valueText = response.data.valueText;

        if (type === "replace") {
          editor.commands.insertContent(`${valueText} `, {
            updateSelection: true,
            parseOptions: {
              preserveWhitespace: "full",
            },
          });
        }

        if (type === "continue") {
          editor.commands.insertContentAt(to + 1, `${valueText}`, {
            parseOptions: {
              preserveWhitespace: "full",
            },
          });
        }
      }
    } catch (error) {
    } finally {
      setLoadingIaSend(false);
    }
  }

  const debouncedEditorState = useDebounce(editorState, 500);
  React.useEffect(() => {
    // save to db
    if (debouncedEditorState === "") return;
    onSave(debouncedEditorState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEditorState]);

  return (
    <>
      <ModalPageLoadingAiNext
        is_open={loadingIaSend}
        onUpdate={(item) => {}}
        title="A IA está analisando suas informações.."
        description="Isso pode levar algum tempo."
        statusModal="processing"
        titleBtn=""
      />
      {/* <ModalAi is_open onClose={() => {}} onCloseSend={() => {}} /> */}

      <Flex
        paddingBottom="20px"
        flexDirection="column"
        width={["100%", "100%", "100vh"]}
      >
        <Flex
          paddingTop="30px"
          paddingBottom="30px"
          paddingRight="20px"
          paddingLeft="20px"
          borderWidth="1px"
          borderColor="#E5E4E4"
          borderTopRadius="10px"
          justifyContent="space-between"
        >
          {editor && <TipTapMenuBar editor={editor} />}
          {isLoadingSave ? (
            <Spinner color="blue.400" />
          ) : (
            <Icon as={CheckCheck} fontSize="25px" color="blue.400" />
          )}
        </Flex>

        {editor && (
          <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
            <div className="bubble-menu">
              <PopoverIa
                onCloseSend={(status) => {
                  genereteText(status);
                }}
              />
            </div>
          </BubbleMenu>
        )}

        {editor && (
          <FloatingMenu editor={editor} tippyOptions={{ zIndex: 9 }}>
            <div className="floating-menu">
              <button
                onClick={() => {
                  genereteText("continue");
                }}
              >
                <BotMessageSquare />
              </button>
            </div>
          </FloatingMenu>
        )}

        <Flex
          minHeight="800px"
          paddingTop="35px"
          paddingBottom="35px"
          paddingRight="20px"
          paddingLeft="20px"
          borderWidth="1px"
          borderColor="#E5E4E4"
          borderBottomRadius="10px"
        >
          <EditorContent style={{ width: "100%" }} editor={editor} />
        </Flex>

        <Flex mt="20px" gap="20px" flexDirection={["column", "column", "row"]}>
          <Button
            isLoading={isLoading}
            type="button"
            bg="blue.400"
            color="white"
            style={{
              borderRadius: 10,
              height: 44,
              fontSize: 16,
              fontWeight: 600,
            }}
            _hover={{
              bg: "blue.400",
            }}
            size="lg"
            cursor="pointer"
            onClick={() => {
              onFinalize();
            }}
            leftIcon={<Icon as={Check} fontSize="18px" color="white" />}
          >
            Finalizar
          </Button>

          {isRewrite && (
            <Button
              isLoading={isLoading}
              type="button"
              bg="transparent"
              color="blue.400"
              borderWidth="1px"
              borderColor="blue.400"
              style={{
                borderRadius: 10,
                height: 44,
                fontSize: 16,
                fontWeight: 600,
              }}
              _hover={{
                bg: "blue.100",
              }}
              size="lg"
              cursor="pointer"
              onClick={() => {
                onRewrite();
              }}
              leftIcon={<Icon as={Bot} fontSize="18px" color="blue.400" />}
            >
              reescrever
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default EditorV2;
