import { useEffect, useRef, useState } from "react";
import { api } from "../../../../service/api";
import { ChatbotMessageDTO } from "../../../../dtos/ChatbotMessageDTO";

export function useListMessageBot(id: string) {
  const [loading, setLoading] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<ChatbotMessageDTO[]>([]);

  const startChatBot = async () => {
    try {
      setLoading(true);
      const posostions = [1, 2, 3];

      let loadDataList = true;

      const dataValues: ChatbotMessageDTO[] = [];

      const promises = posostions.map(async (po, idx) => {
        const response = await api.post("chatbot/start/message/create", {
          biography_id: id,
          position: po,
        });

        const data = response.data;

        if (data.status === "chat_new") {
          setLoadingAll(true);
          const ld = [...dataValues, data.dataMessage];
          setDataList(ld);
          console.log("processando:", po);
          dataValues.push(data.dataMessage);
          loadDataList = false;
        }
      });

      await Promise.all(promises);

      if (loadDataList) {
        await fetchData(true);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    } finally {
      setLoading(false);
      setLoadingAll(false);
    }
  };

  const fetchData = async (type_read: boolean) => {
    try {
      setLoading(true);
      const response = await api.get(`chatbot/message/list/${id}`);

      const responseData: ChatbotMessageDTO[] = response.data;

      const newResponseData = await Promise.all(
        responseData.map(async (item) => {
          return {
            ...item,
            type_read,
          };
        })
      );

      setDataList(newResponseData);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const initialized = useRef("");

  useEffect(() => {
    if (id) {
      if (initialized.current !== id) {
        initialized.current = id;
        startChatBot();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    dataList,
    loading,
    loadingAll,
    error,
    refetch: fetchData,
  };
}
