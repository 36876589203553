import { useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { Title } from "../../components/Title";
import {
  ButtonSelectTypePlan,
  typeSelected,
} from "./components/ButtonSelectTypePlan";
import { CardPlan } from "./components/cardPlan";
import { plansFree } from "../../data/data";
import { useAuth } from "../../hooks/useAuth";
import { useListPlan } from "./hooks/useListPlan";
import { FullLoading } from "../../components/fullLoading";

export function Plan() {
  const { user } = useAuth();
  const { dataList, loading } = useListPlan();
  const [typePlan, setTypePlan] = useState<typeSelected>({ type: "month" });

  return (
    <>
      {loading && <FullLoading />}
      {!loading && dataList && (
        <Flex
          width={["auto", "auto", "100%"]}
          marginTop="41px"
          marginBottom="50px"
          flexDirection="column"
          display="none"
        >
          <Title title="Selecione seu plano" />

          <Text
            maxWidth="777px"
            marginTop="41px"
            color="#1E1E1E"
            fontWeight={400}
            fontSize="16px"
          ></Text>
          <Flex margin={["auto", "auto", "inherit"]} marginTop="41px">
            <ButtonSelectTypePlan
              onSelected={(type) => {
                setTypePlan(type);
              }}
              typeSelected={typePlan}
            />
          </Flex>

          <Flex
            marginTop="41px"
            gap={10}
            flexDirection={["column", "column", "row"]}
          >
            <CardPlan
              title="Escritor Basic"
              titleButton="Startar Plano FREE"
              typePlan="free"
              type={typePlan.type}
              plans={plansFree}
              subscription={user.subscription}
            />
            <CardPlan
              title="Escritor pro PRO"
              titleButton="Contratar Plano"
              typePlan="pro"
              type={typePlan.type}
              plans={dataList}
              subscription={user.subscription}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
}
