export function IconPdf() {
  return (
    <svg
      width={33}
      height={34}
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.994 9.629H3.006C1.35 9.629 0 10.969 0 12.617V24.57c0 1.648 1.349 2.989 3.006 2.989h26.988c1.657 0 3.006-1.34 3.006-2.989V12.617c0-1.648-1.349-2.988-3.006-2.988zM7.932 20.143l-1.103.004v2.298c0 .55-.45.996-1.002.996a.999.999 0 01-1.003-.996l-.014-7.567a.99.99 0 01.618-.923c.121-.05.252-.076.384-.076h2.12c1.76 0 3.193 1.405 3.193 3.132s-1.432 3.133-3.193 3.133zm8.602 3.058c-.597.01-2.09.016-2.153.016h-.004c-.265 0-.52-.104-.707-.29a.993.993 0 01-.295-.703l-.015-7.347a.99.99 0 01.619-.922c.121-.05.252-.076.383-.076h2.093c2.385 0 3.986 1.876 3.986 4.669 0 2.657-1.643 4.613-3.907 4.653zm10.268-5.713a1 1 0 011.002.996 1 1 0 01-1.002.996h-1.818v2.832a1 1 0 01-1.002.997 1 1 0 01-1.002-.997v-7.514a1 1 0 011.002-.996h3.064a1 1 0 011.002.996 1 1 0 01-1.002.996h-2.062v1.694h1.818z"
        fill="#36A9E1"
      />
      <path
        d="M16.455 15.871h-1.089c.002.9.007 4.483.01 5.349.417-.003.864-.007 1.123-.011 1.339-.023 1.938-1.353 1.938-2.661 0-.628-.143-2.677-1.982-2.677zm-8.523 0H6.817l.002 1.14.004 1.144 1.11-.004c.644 0 1.188-.522 1.188-1.14 0-.618-.544-1.14-1.189-1.14zm21.023-8.234a4.967 4.967 0 00-1.07-1.719l-4.162-4.364A5.046 5.046 0 0020.087 0H6.747C5.089 0 3.741 1.34 3.741 2.988v4.649h25.215zM3.742 29.55v1.46C3.74 32.66 5.089 34 6.747 34h19.506c1.658 0 3.006-1.34 3.006-2.988V29.55H3.741z"
        fill="#36A9E1"
      />
    </svg>
  );
}
