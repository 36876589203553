export function IconHeaderBookDataHeader() {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 0h1v19h-1c-7.05 0-10.89 0-13 1.2v-19C18.11 0 21.95 0 29 0zm-8.9 15.2c1.28-.13 2.89-.17 4.91-.19l-.02-2c-2.07.02-3.73.06-5.09.2l.2 1.99zm0-4c1.28-.13 2.89-.17 4.91-.19l-.02-2c-2.07.02-3.73.06-5.09.2l.2 1.99zm0-4c1.28-.13 2.89-.17 4.91-.19l-.02-2c-2.07.02-3.73.06-5.09.2l.2 1.99zM1 0c7.05 0 10.89 0 13 1.2v19C11.89 19 8.05 19 1 19H0V0h1zm8.9 15.2l.2-1.99c-1.36-.14-3.02-.18-5.09-.2l-.02 2c2.02.02 3.63.06 4.91.19zm0-4l.2-1.99c-1.36-.14-3.02-.18-5.09-.2l-.02 2c2.02.02 3.63.06 4.91.19zm0-4l.2-1.99c-1.36-.14-3.02-.18-5.09-.2l-.02 2c2.02.02 3.63.06 4.91.19zm1 14.43l-.86 2.59c.23.03.43.06.6.09.49.64 1.71 1.69 4.34 1.69s3.87-1.06 4.37-1.69C20.89 24 25.18 24 29 24h1v-3h-1c-9.93 0-12.31.08-13.05 2.32h-1.9c-.37-1.12-1.15-1.7-3.07-2-.02.1-.04.2-.08.31zM0 28l2 2 2-2 2 2 3-9-9 3 2 2-2 2z"
        fill="#683C11"
      />
    </svg>
  );
}
