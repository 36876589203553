import { Flex, Icon, Text } from "@chakra-ui/react";
import { IconHeaderBaseInformation } from "../icons/iconHeaderBaseInformation";

export function BaseInformationHeader() {
  return (
    <Flex alignItems="center" gap="15px">
      <Icon as={IconHeaderBaseInformation} fontSize="18px" color="blue.400" />
      <Text color="#3A3A3A" fontSize="24px" fontWeight="bold">
        Base de conhecimento
      </Text>
    </Flex>
  );
}
