import { useState } from "react";
import { api } from "../../../../service/api";
import { BookDataDTO } from "../../../../dtos/BookDataDTO";

type Props = {
  id: string;
  category_name: string;
};

export function useListBookData() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<BookDataDTO[]>([]);

  const fetchData = async ({ id, category_name }: Props) => {
    try {
      setLoading(true);
      const response = await api.get(
        `book/chapter/list/${id}/${category_name}`
      );
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
