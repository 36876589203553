export function IconBook() {
  return (
    <svg
      width={22}
      height={19}
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.927 2.442C7.653.947 4.497.268 2.14.012.962-.116 0 .826 0 1.988v11.569c0 1.161.962 2.103 2.14 2.23 2.357.255 5.513.936 7.787 2.431V2.442zm1.61 15.776c.74-.487 1.577-.888 2.446-1.215a4.937 4.937 0 01.416-2.629 5.181 5.181 0 011.733-2.077 5.506 5.506 0 012.585-.97c.936-.1 1.883.037 2.746.398V1.989c0-1.163-.961-2.105-2.14-1.977-2.355.254-5.512.935-7.786 2.43v15.776zM2.424 5.28a.766.766 0 01.32-.503.833.833 0 01.599-.14c1.398.208 2.973.555 4.46 1.104.2.074.36.22.446.408a.74.74 0 01.014.59.787.787 0 01-.425.426.84.84 0 01-.617.014c-1.35-.5-2.806-.824-4.125-1.019a.811.811 0 01-.526-.306.747.747 0 01-.146-.574zm.919 3.466a.84.84 0 00-.607.133.785.785 0 00-.217.223.75.75 0 00-.095.589c.025.1.072.192.135.275a.829.829 0 00.537.303c.665.098 1.366.23 2.073.4.208.05.427.019.61-.086a.774.774 0 00.368-.473.742.742 0 00-.09-.584.803.803 0 00-.495-.351 25.32 25.32 0 00-2.22-.429zm16.51 5.377c0-.205-.084-.4-.235-.545a.824.824 0 00-.57-.226.824.824 0 00-.568.226.754.754 0 00-.236.545v1.284h-1.342a.823.823 0 00-.569.225.754.754 0 00-.235.545c0 .204.084.4.235.544.151.145.356.226.57.226h1.34v1.284c0 .204.086.4.237.544a.824.824 0 00.569.226.824.824 0 00.569-.226.754.754 0 00.236-.544v-1.284h1.341a.824.824 0 00.57-.226.754.754 0 00.235-.544c0-.205-.085-.4-.236-.545a.824.824 0 00-.569-.226h-1.341v-1.283z"
        fill="#FFFFFF"
      />
    </svg>
  );
}
