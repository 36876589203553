import { Flex, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  icon: any;
  link: string;
};

export function ButtonStatusSubscription({ title, icon, link }: Props) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      backgroundColor="blue.400"
      px="20px"
      height="43px"
      borderRadius="50px"
      cursor="pointer"
      as="a"
      href={link}
      width={["100%", "100%", "auto"]}
    >
      <Text style={{ fontWeight: 400, fontSize: 18 }} color="white">
        {title}
      </Text>
      <Flex ml="5px">{icon}</Flex>
    </Flex>
  );
}
