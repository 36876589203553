import {
  Flex,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  Icon,
  Spinner,
  useToast,
  InputRightElement,
  IconButton,
  Button,
} from "@chakra-ui/react";

import { BoxMessageUser } from "../boxMessageUser";
import { BoxMessageBot } from "../boxMessageBot";
import { IconInputchatMessage } from "../iconInputchatMessage";
import { useEffect, useRef, useState } from "react";
import { useListMessageBot } from "../../hooks/useListMessageBot";
import { api } from "../../../../../service/api";
import { ChatbotMessageDTO } from "../../../../../dtos/ChatbotMessageDTO";
import { SendHorizonal } from "lucide-react";
import { useStatusMessageBot } from "../../hooks/useStatusMessageBot";
import { useNavigate } from "react-router-dom";
// import { useSpeechRecognition } from "react-speech-kit";

type Props = {
  id: string;
};

export function ChatMessage({ id }: Props) {
  const navigate = useNavigate();

  const { dataList, loading, refetch } = useListMessageBot(id);
  const toast = useToast();

  const scrollableRootRef = useRef<any>(null);

  const [text, setText] = useState("");
  // const [textOld, setTextOld] = useState("");
  const [textUpdate, setTextUpdate] = useState("");
  const [loadingSend, setLoadingSend] = useState(false);
  const [questionTypeWrite, setQuestionTypeWrite] = useState<string[]>([]);
  const [questionTypeWriteText, setQuestionTypeWriteText] = useState<string[]>(
    []
  );

  // const { listen, listening, stop } = useSpeechRecognition({
  //   onResult: (result: string) => {
  //     if (result.length < text.length) {
  //       setTextOld(text);
  //     } else if (textOld.length > text.length) {
  //       let newTextValue = textOld + " " + text;
  //       setText(newTextValue);
  //     } else {
  //     }
  //   },
  //   changeLang: (result: any) => {
  //     // setText(result);
  //   },
  //   onEnd: (result: any) => {
  //     console.log(result);
  //   },
  // });

  // const toggle = listening
  //   ? stop
  //   : () => {
  //       listen({ lang: "pt-BR" });
  //     };

  const { statusMessage, refetch: refetchMessage } = useStatusMessageBot(id);

  const [lastMessageBot, setLastMessageBot] = useState<ChatbotMessageDTO[]>([]);
  const [typeMessageBot, setTypeMessageBot] = useState<string>("");

  async function sendMessage(type = "", newMessage = "") {
    try {
      setLoadingSend(true);
      const lastMessage = lastMessageBot[lastMessageBot.length - 1];
      await api.post("chatbot/message/create", {
        id_message: lastMessage.id,
        biography_id: id,
        message: text === "" ? newMessage : text,
        type,
      });
      setText("");
      refetch(false);
      refetchMessage(id);
    } catch (error) {
      toast({
        position: "top-right",
        title: "Meu Biógrafo",
        description: "Não foi possível enviar messagem.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingSend(false);
    }
  }

  function upScrollChat() {
    scrollableRootRef.current?.lastElementChild.scrollIntoView({
      behavior: "smooth",
    });
  }

  useEffect(() => {
    if (dataList.length > 0) {
      upScrollChat();
    }
  }, [dataList.length, lastMessageBot, textUpdate]);

  useEffect(() => {
    if (dataList.length > 0) {
      let data = dataList.filter((item) => item.type === "bot");
      setLastMessageBot(data);
      setTypeMessageBot(data[data.length - 1].name);
    }
  }, [dataList]);

  useEffect(() => {
    if (typeMessageBot === "question_type_write") {
      let newText = questionTypeWriteText.join(", ");
      setText(newText);
    }
  }, [typeMessageBot, questionTypeWriteText]);

  return (
    <>
      <Flex
        width="100%"
        height="90vh"
        px={["0px", "0px", "200px"]}
        py={["20px", "20px", "20px"]}
      >
        <Flex width="100%" flexDirection="column">
          <Flex
            flexDirection="column"
            overflowY="scroll"
            gap="30px"
            minHeight="67vh"
            ref={scrollableRootRef}
          >
            {dataList &&
              dataList.map((item, _) => {
                if (item.type === "bot") {
                  return (
                    <BoxMessageBot
                      isLoading={loadingSend}
                      upScrollChatValue={(textUpdate) => {
                        setTextUpdate(textUpdate);
                      }}
                      textSeleted={questionTypeWriteText}
                      key={item.id}
                      data={item}
                      type={typeMessageBot}
                      onClick={(typeText) => {
                        if (typeMessageBot === "question_type_write") {
                          let indiceText = questionTypeWriteText.findIndex(
                            (obj) => obj === typeText.text
                          );

                          let indiceType = questionTypeWrite.findIndex(
                            (obj) => obj === typeText.type
                          );

                          if (indiceText >= 0 && indiceType >= 0) {
                            questionTypeWriteText.splice(indiceText, 1);
                            questionTypeWrite.splice(indiceType, 1);
                            setQuestionTypeWrite([...questionTypeWrite]);
                            setQuestionTypeWriteText([
                              ...questionTypeWriteText,
                            ]);

                            return;
                          }

                          setQuestionTypeWrite([
                            ...questionTypeWrite,
                            typeText.type,
                          ]);
                          setQuestionTypeWriteText([
                            ...questionTypeWriteText,
                            typeText.text,
                          ]);
                        } else {
                          setText(typeText.text);
                          sendMessage(typeText.type, typeText.text);
                        }
                      }}
                      onSend={() => {
                        sendMessage();
                      }}
                    />
                  );
                }
                if (item.type === "user") {
                  return (
                    <BoxMessageUser key={item.id} message={item.message} />
                  );
                }

                return null;
              })}
          </Flex>
          <InputGroup mt="30px">
            <InputLeftElement width="50px" marginTop="10px" marginLeft="10px">
              {!loading && !loadingSend && (
                <Icon
                  as={IconInputchatMessage}
                  fontSize="18px"
                  color="blue.400"
                />
              )}
              {(loading || loadingSend) && <Spinner color="blue.400" />}
            </InputLeftElement>
            <ChakraInput
              width="100%"
              paddingLeft="60px"
              paddingRight="60px"
              id="message-chat"
              name="message-chat"
              borderColor="gray.50"
              borderWidth={1}
              focusBorderColor="gray.100"
              bgColor="transparent"
              color="black"
              variant="filled"
              borderRadius={"30px"}
              minHeight={"60px"}
              height="100%"
              _hover={{
                bgColor: "transparent",
              }}
              style={{ fontSize: 14, overflow: "auto" }}
              _placeholder={{ color: "#000" }}
              placeholder="Digite aqui sua resposta"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  sendMessage();
                }
              }}
              onChange={(e) => setText(e.target.value)}
              value={text}
              autoComplete="off"
              isDisabled={
                loading ||
                loadingSend ||
                typeMessageBot === "question_me_question" ||
                typeMessageBot === "question_type_write"
              }
            />
            <InputRightElement width="50px" marginTop="10px" marginRight="10px">
              <IconButton
                isRound={true}
                variant="solid"
                backgroundColor="blue.400"
                _hover={{
                  backgroundColor: "blue.400",
                }}
                aria-label="Done"
                fontSize="20px"
                icon={<Icon as={SendHorizonal} fontSize="18px" color="white" />}
                isDisabled={
                  loading ||
                  loadingSend ||
                  typeMessageBot === "question_me_question"
                }
                onClick={() => {
                  sendMessage();
                }}
              />
            </InputRightElement>
          </InputGroup>

          {/* <button type="button" onClick={toggle}>
            {listening ? "Stop" : "Listen"}
          </button>

          {listening && <div>Go ahead I'm listening</div>} */}

          {statusMessage && (
            <Flex margin="auto" mt="30px">
              <Button
                color="blue.400"
                variant="link"
                onClick={() => {
                  navigate(`/biografia/base-conhecimento/${id}?type=infancy`);
                }}
              >
                Base de Conhecimento
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
}
