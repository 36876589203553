export function IconSelectBox() {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.394 0a.684.684 0 00-.47.206L.199 2.929a.68.68 0 000 .958l2.727 2.717a.685.685 0 001.167-.481.68.68 0 00-.2-.482l-1.56-1.554h10.627v2.08a.678.678 0 00.401.695.685.685 0 00.958-.694v-2.08a1.367 1.367 0 00-.4-.959 1.376 1.376 0 00-.959-.403H2.324l1.567-1.56A.68.68 0 003.394 0zm8.189 8.153a.684.684 0 00-.664.822.68.68 0 00.192.347l1.565 1.562H2.043V8.862a.679.679 0 00-.688-.688.685.685 0 00-.675.688v2.022c0 .74.618 1.354 1.366 1.354h10.63L11.11 13.8a.68.68 0 00.74 1.131c.085-.04.16-.1.222-.17l2.727-2.719a.68.68 0 000-.963l-2.725-2.718a.684.684 0 00-.49-.205l-.002-.003z"
        fill="#000"
      />
    </svg>
  );
}
