import { useEffect, useState } from "react";
import { api } from "../../../../../service/api";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Flex,
  Image,
} from "@chakra-ui/react";
import { BoxLoading } from "../../../../../components/boxLoading";
import { ButtonBaseInformationAdd } from "../../../baseInformation/components/buttonBaseInformationAdd";
import { useShowBookData } from "../../hooks/useShowBookData";

type Props = {
  id: string;
  pageType: string;
};
export function PageCover({ id, pageType }: Props) {
  const { dataList, loading, refetch } = useShowBookData();

  const [loadingSend, setLoadingSend] = useState(false);
  const { open } = useDropzone({
    noKeyboard: true,
    maxFiles: 0,
    noClick: true,
    multiple: false,
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
    },
    onDrop: (files) => sendDocuments(files[0]),
  });

  async function deleteData(id: string) {
    try {
      await api.delete(`book/chapter/delete/${id}`);
      getData();
    } catch (error) {}
  }

  async function sendDocuments(file: File) {
    try {
      setLoadingSend(true);
      const dataDocument = new FormData();
      dataDocument.append("biography_id", id);
      dataDocument.append("category_name", "cover");
      dataDocument.append("title", "Capa");
      dataDocument.append("path", file);
      await api.post("book/chapter/create", dataDocument);
      getData();
    } catch (error) {
    } finally {
      setLoadingSend(false);
    }
  }

  function getData() {
    refetch({
      id,
      categoryName: "cover",
    });
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageType]);

  return (
    <Flex flexDirection="column" mt="5px" position="relative">
      {loading && <BoxLoading />}
      {!dataList.id && (
        <Box>
          <ButtonBaseInformationAdd
            isLoading={loadingSend}
            title="Carregar capa"
            onPress={() => {
              open();
            }}
          />
        </Box>
      )}
      <Flex mt="25px">
        {!loading && dataList && dataList.id && (
          <Card maxW="lg">
            <CardBody>
              <Image
                width="lg"
                src={dataList.path}
                alt={dataList.title}
                borderRadius="lg"
              />
            </CardBody>
            <Divider />
            <CardFooter>
              <Button
                variant="outilne"
                colorScheme="blue"
                onClick={() => {
                  deleteData(dataList.id);
                }}
              >
                Excluir capa
              </Button>
            </CardFooter>
          </Card>
        )}
      </Flex>
    </Flex>
  );
}
