import { Flex, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  value: string;
};

export function CardItemLine({ title, value }: Props) {
  return (
    <Flex
      flexDirection="column"
      borderBottomWidth="1px"
      borderBottomColor="#DDDDDD"
      paddingBottom="10px"
    >
      <Text color="#000000" fontSize="12px" fontWeight={500}>
        {title}
      </Text>
      <Text color="#36A9E1" fontSize="16px" fontWeight={500}>
        {value}
      </Text>
    </Flex>
  );
}
