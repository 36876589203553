import { useState } from "react";
import { api } from "../../../../service/api";
import { PageDataDTO } from "../../../../dtos/PageDataDTO";

type Props = {
  id: string;
};

export function useListPageData() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<PageDataDTO>({} as PageDataDTO);

  const fetchData = async ({ id }: Props) => {
    try {
      setLoading(true);
      const response = await api.get(`book/page/show/${id}`);
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
