import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import Logo from "../../../../assets/logo.png";

export function RegisterAccountSuccess() {
  return (
    <Flex
      w="100vw"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      px={40}
      py={20}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={400}
      >
        <Image
          src={Logo}
          style={{ width: "333px", height: "40px" }}
          alt="Logo"
        />

        <Text mt={5} textAlign="center" fontSize="25px" color="gray.800">
          Conta criada com sucesso
        </Text>

        <Flex mt={5} alignItems="center" justifyContent="center">
          <Link href="/login" color="blue.400" fontWeight="bold">
            Voltar
          </Link>
        </Flex>
      </Box>
    </Flex>
  );
}
