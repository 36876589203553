import { createContext, useContext } from "react";
import { IBiographyContextData } from "./biography.interface";

export const BiographyContext = createContext<IBiographyContextData>(
  {} as IBiographyContextData
);

export const useBiographyContext = (): IBiographyContextData => {
  const context = useContext(BiographyContext);
  return context;
};
