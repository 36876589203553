export function IconEditBaseInformation() {
  return (
    <svg
      width={12}
      height={11}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.686 1.336L10.638.287a1.236 1.236 0 00-1.747.164l-1.28 1.28 2.633 2.633 1.279-1.28c.237-.228.39-.53.438-.855a1.053 1.053 0 00-.275-.893zM.987 9.832a1.062 1.062 0 00.275.885 1.016 1.016 0 00.729.283c.054 0 .109-.002.163-.008l1.212-.163c.324-.053.624-.207.855-.44l5.502-5.504L7.091 2.25 1.59 7.756a1.559 1.559 0 00-.438.856l-.164 1.22z"
        fill="#A2A2A2"
      />
    </svg>
  );
}
