import { useState } from "react";
import { BookDataDTO } from "../../../../dtos/BookDataDTO";
import { api } from "../../../../service/api";

type Props = {
  id: string;
  categoryName: string;
};

export function useShowBookData() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<BookDataDTO>({} as BookDataDTO);

  const fetchData = async ({ id, categoryName }: Props) => {
    try {
      setLoading(true);
      const response = await api.get(`book/chapter/show/${id}/${categoryName}`);
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
