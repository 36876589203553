import { useState } from "react";
import { api } from "../../../../service/api";

type Props = {
  biography_id: string;
  category_name: string;
};

export function useCheckMenu() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [statusPage, setStatusPage] = useState<
    "created" | "active" | "complete"
  >("created");

  const fetchData = async ({ biography_id, category_name }: Props) => {
    try {
      setLoading(true);
      const response = await api.get(
        `book/chapter/show/status/${biography_id}/${category_name}`
      );
      setStatusPage(response.data.status);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    statusPage,
    loading,
    error,
    refetch: fetchData,
  };
}
