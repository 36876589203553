import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import { ModalDeletetProps } from "./ModalDelete.types";
import { api } from "../../service/api";
import { useState } from "react";

export function ModalDelete({
  is_open,
  api_url,
  title,
  description,
  onClose,
  onCloseSend,
  ...rest
}: ModalDeletetProps) {
  const [isLoading, setIsLoading] = useState(false);

  async function deleteData() {
    try {
      setIsLoading(true);
      await api.delete(api_url);
      onCloseSend();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          onClose();
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="md"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalBody>
            <Flex
              padding="20px"
              alignItems="center"
              justifyContent="center"
              width="100%"
              flexDirection="column"
            >
              <div
                style={{ textAlign: "center", width: "100%" }}
                className="flex flex-col space-y-4 mb-4 bg-red"
              >
                <p>
                  <span className="font-semibold">{title}</span>
                </p>
                <p>{description}</p>
              </div>

              <div className="flex space-x-4" style={{ width: "100%" }}>
                <Button
                  width="100%"
                  bg="blue.400"
                  _hover={{
                    bg: "blue.400",
                  }}
                  isLoading={isLoading}
                  onClick={() => {
                    deleteData();
                  }}
                >
                  Sim
                </Button>
                <Button
                  variant="outline"
                  width="100%"
                  _hover={{
                    bg: "blue.400",
                  }}
                  onClick={() => {
                    onClose();
                  }}
                  isLoading={isLoading}
                >
                  Não
                </Button>
              </div>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
