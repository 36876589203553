import { Flex, Icon, Text } from "@chakra-ui/react";
import { IconEditBaseInformation } from "../../../components/icons/iconEditBaseInformation";
import { RiAddCircleLine } from "react-icons/ri";
import { BaseInformationDTO } from "../../../../../../dtos/BaseInformationDTO";
import { ModalNewIntroductionText } from "../modal/modalNewIntroductionText";
import { useState } from "react";
import { ModalDelete } from "../../../../../../components/modalDelete";
import { statusModalNewIntroductionText } from "../modal/modalNewIntroductionText/ModalNewIntroductionText.types";

type Props = {
  id: string;
  data: BaseInformationDTO | null;
  description: string;
  type: string;
  biography_id: string;
  onCloseSend: () => void;
};

export function IntroductionTextContent({
  id,
  data,
  description,
  type,
  biography_id,
  onCloseSend,
}: Props) {
  const [isOpenNewText, setIsOpenNewText] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [typeModal, setTypeModal] =
    useState<statusModalNewIntroductionText>("start");

  async function deleteData() {
    setIsOpenDelete(true);
  }

  return (
    <>
      <ModalNewIntroductionText
        id_form={data?.id}
        text_input={textInput}
        type_modal={typeModal}
        title={description}
        id={id}
        type={type}
        biography_id={biography_id}
        is_open={isOpenNewText}
        onClose={() => {
          setIsOpenNewText(false);
          setTextInput("");
          setTypeModal("start");
        }}
        onCloseSend={() => {
          onCloseSend();
          setIsOpenNewText(false);
          setTextInput("");
          setTypeModal("start");
        }}
      />

      <ModalDelete
        is_open={isOpenDelete}
        api_url={`baseinformation/delete/${data?.id}`}
        title="Deletar resposta"
        description="Deseja deletar a resposta?"
        onClose={() => {
          setIsOpenDelete(false);
        }}
        onCloseSend={() => {
          setIsOpenDelete(false);
          onCloseSend();
        }}
      />

      <Flex
        flexDirection="column"
        gap="13px"
        padding="20px"
        borderRadius="10px"
        borderWidth="1px"
        borderColor="#DDDDDD"
        position="relative"
      >
        {/* <BoxLoading calcHeight="20px" calcWidth="20px" borderRadius="10px" /> */}
        <Text color="#3A3A3A" fontWeight={400}>
          {description}
        </Text>

        {!data && (
          <Flex alignItems="center" justifyContent="space-between">
            <Flex gap="20px">
              <Flex
                gap="5px"
                cursor="pointer"
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                  setTypeModal("create");
                  setIsOpenNewText(true);
                }}
              >
                <Icon as={RiAddCircleLine} fontSize="18px" color="#469B93" />
                <Text color="#469B93" fontWeight="bold">
                  Responder
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}

        {data && (
          <Flex
            mt="10px"
            flexDirection="column"
            gap="13px"
            padding="20px"
            borderRadius="5px"
            borderWidth="1px"
            borderColor="#DDDDDD"
            position="relative"
          >
            <Text fontSize="14px" fontWeight="normal">
              {data.description}
            </Text>

            <Flex gap="20px">
              <Flex
                gap="5px"
                cursor="pointer"
                onClick={() => {
                  deleteData();
                }}
              >
                <Text color="#FF5656" fontWeight="bold">
                  Excluir
                </Text>
              </Flex>
              <Flex
                gap="5px"
                cursor="pointer"
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                  setTextInput(data.description);
                  setTypeModal("update");
                  setIsOpenNewText(true);
                }}
              >
                <Icon
                  as={IconEditBaseInformation}
                  fontSize="18px"
                  color="blue.400"
                />
                <Text color="#A1A1A1" fontWeight="bold">
                  Editar
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
}
