export function IconInputchatMessage() {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_51_426)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.037 16.357a1.176 1.176 0 00-2.207 0l-1.4 3.788-3.79 1.401a1.178 1.178 0 000 2.208l3.79 1.402 1.4 3.788a1.176 1.176 0 002.207 0l1.4-3.788 3.79-1.402a1.175 1.175 0 000-2.208l-3.79-1.401-1.4-3.788zM13.467 1.056a1.18 1.18 0 00-2.214 0l-2.83 7.655-7.65 2.83a1.175 1.175 0 000 2.208l7.65 2.832 2.83 7.654a1.18 1.18 0 002.214 0l2.83-7.654 7.65-2.832a1.175 1.175 0 000-2.208l-7.65-2.831-2.83-7.654z"
          fill="#36A9E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_51_426">
          <path
            fill="#fff"
            transform="translate(0 .287)"
            d="M0 0H30V29.4262H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
