import { Flex, Text } from "@chakra-ui/react";

type Props = {
  message: string;
};
export function BoxMessageUser({ message }: Props) {
  return (
    <Flex
      alignSelf="flex-end"
      background="blue.100"
      borderRadius="20px"
      p="20px"
    >
      <Text fontSize="16px" fontWeight={400} color="#1E1E1E">
        {message}
      </Text>
    </Flex>
  );
}
