import { useState } from "react";
import { BaseInformationDTO } from "../../../../dtos/BaseInformationDTO";
import { api } from "../../../../service/api";

type Props = {
  id: string;
  type: string;
  locality: string;
};

export function useListBaseInformations() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<BaseInformationDTO[]>([]);

  const fetchData = async (data: Props) => {
    try {
      setLoading(true);
      const response = await api.get(
        `baseinformation/list/${data.id}/${data.type}/${data.locality}`
      );
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
