import { Flex, Text } from "@chakra-ui/react";

type Props = {
  icon: any;
  title: string;
  onPress: () => void;
  isActive: boolean;
};

export function MenuOption({ title, icon, onPress, isActive }: Props) {
  return (
    <Flex cursor="pointer" onClick={onPress} alignItems="center" gap="10px">
      {icon}
      <Text color="#1E1E1E" fontWeight={isActive ? 800 : 500} fontSize="14px">
        {title}
      </Text>
    </Flex>
  );
}
