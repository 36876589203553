import { Button, Icon } from "@chakra-ui/react";
import { IconUploadBaseInformation } from "../icons/iconUploadBaseInformation";

type Props = {
  title: string;
  onPress: () => void;
  isLoading?: boolean;
};

export function ButtonBaseInformationAdd({
  title,
  onPress,
  isLoading = false,
}: Props) {
  return (
    <Button
      type="button"
      bg="blue.400"
      color="white"
      style={{
        borderRadius: 50,
        height: 44,
        fontSize: 16,
        fontWeight: 600,
      }}
      _hover={{
        bg: "blue.400",
      }}
      size="lg"
      isLoading={isLoading}
      rightIcon={
        <Icon as={IconUploadBaseInformation} fontSize="18px" color="blue.400" />
      }
      onClick={onPress}
    >
      {title}
    </Button>
  );
}
