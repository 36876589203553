import { Center, CircularProgress, Flex, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  icon: any;
  onPress: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export function ButtonBioOptions({
  title,
  icon,
  onPress,
  isDisabled = false,
  isLoading = false,
}: Props) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      backgroundColor={isDisabled || isLoading ? "#f5f5f5" : "transparent"}
      borderWidth="1px"
      borderColor="#A1A1A1"
      px="20px"
      height="64px"
      borderRadius="5px"
      cursor={isDisabled || isLoading ? "no-drop" : "pointer"}
      as="button"
      gap="10px"
      onClick={onPress}
    >
      {!isLoading && (
        <>
          <Text style={{ fontWeight: 400, fontSize: 18, color: "#3A3A3A" }}>
            {title}
          </Text>
          <Flex ml="5px">{icon}</Flex>
        </>
      )}
      {isLoading && (
        <Center>
          <CircularProgress color="#36A9E1" isIndeterminate />
        </Center>
      )}
    </Flex>
  );
}
