import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { ContainerSmall } from "../../../components/ContainerSmall";
import { api } from "../../../service/api";
import { Input } from "../../../components/form/input";
import { useAuth } from "../../../hooks/useAuth";
import { useEffect } from "react";
import { InputMask } from "../../../components/form/inputMask";

type createFormData = {
  first_name: string;
  surname: string;
  email: string;
  document: string;
  cellphone: string;
};

const createFormDataSchema = yup.object().shape({
  first_name: yup.string().required("Campo obrigatório."),
  surname: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .required("Ccampo obrigatório.")
    .email("Email não é valido"),
  document: yup.string().required("Campo obrigatório."),
  cellphone: yup.string().required("Campo obrigatório."),
});

export function AccountMe() {
  const toast = useToast();

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      await api.put("user/update", value);

      toast({
        position: "top-right",
        title: "Minha conta",
        description: "Conta atualizada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível criar conta.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (user.id) {
      setValue("first_name", user.first_name);
      setValue("surname", user.surname);
      setValue("email", user.email);
      setValue("cellphone", user.cellphone ?? "");
      setValue("document", user.document ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  return (
    <ContainerSmall>
      <Flex flexDirection="column" width="100%">
        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(handleSend)}
        >
          <Flex flexDirection="column" width={["100%", "100%", "600px"]}>
            <Text style={{ fontWeight: 500, fontSize: 24 }} color="#181818">
              Minha conta
            </Text>
            <Flex flexDirection="column" style={{ marginTop: 26 }}>
              <Input
                label="Nome"
                placeholder="Seu nome"
                error={errors.first_name}
                {...register("first_name")}
              />
              <Box style={{ marginTop: 10 }}>
                <Input
                  label="Sobrenome"
                  placeholder="Seu último nome"
                  error={errors.surname}
                  {...register("surname")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <Input
                  isDisabled
                  label="Endereço de email"
                  placeholder="seu@email.com"
                  type="email"
                  error={errors.email}
                  {...register("email")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputMask
                  mask="999.999.999-99"
                  label="CPF"
                  placeholder="000.000.000-00"
                  error={errors.document}
                  {...register("document")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputMask
                  mask="(99)99999-9999"
                  label="Telefone"
                  placeholder="(99)99999-9999"
                  error={errors.cellphone}
                  {...register("cellphone")}
                />
              </Box>
              <Box>
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  bg="blue.400"
                  color="white"
                  style={{
                    borderRadius: 10,
                    height: 44,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  _hover={{
                    bg: "blue.400",
                  }}
                  mt="6"
                  size="lg"
                >
                  Atualizar
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ContainerSmall>
  );
}
