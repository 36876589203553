import { Box, Flex, Icon, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { ButtonBaseInformationAdd } from "../../../components/buttonBaseInformationAdd";
import { FileRejection, useDropzone } from "react-dropzone";
import { api } from "../../../../../../service/api";
import { useEffect, useState } from "react";
import { IconBoxDocumentBaseInformation } from "../../../components/icons/iconBoxDocumentBaseInformation";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useListBaseInformations } from "../../../hooks/useListBaseInformations";
import { BoxLoading } from "../../../../../../components/boxLoading";
import { ModalPageLoadingAiNext } from "../../../../../../components/modalPageLoadingAiNext";
import { ModalDelete } from "../../../../../../components/modalDelete";

const LOCALITY = "documents";

type Props = {
  id: string;
  type: string;
};

export function IntroductionDocuments({ id, type }: Props) {
  const { dataList, loading, refetch } = useListBaseInformations();

  const [loadingSend, setLoadingSend] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [idCard, setIdCard] = useState("");
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const { open } = useDropzone({
    noKeyboard: true,
    maxFiles: 0,
    noClick: true,
    multiple: false,
    minSize: 0,
    maxSize: 10242880,
    onDrop: (files, fileRejections) => sendDocuments(files[0], fileRejections),
    onError: (e) => {},
  });

  async function sendDocuments(file: File, fileRejections: FileRejection[]) {
    try {
      setLoadingSend(true);

      if (fileRejections.length > 0) {
        if (fileRejections[0].errors[0].code === "file-too-large") {
          setErrorTitle("Arquivo maior que de 10MB não é permitido.");
        } else {
          setErrorTitle("Não foi possível continuar com arquivo.");
        }
        setIsError(true);
        return;
      }

      const dataDocument = new FormData();
      dataDocument.append("biography_id", id);
      dataDocument.append("type", type);
      dataDocument.append("locality", LOCALITY);
      dataDocument.append("path", file);
      dataDocument.append("title", file.name);
      dataDocument.append("description", file.name);
      await api.post("baseinformation/create", dataDocument);
      getData();
    } catch (error) {
    } finally {
      setLoadingSend(false);
    }
  }

  async function deleteData(id: string) {
    try {
      setIdCard(id);
      setIsOpenDelete(true);
    } catch (error) {}
  }

  function getData() {
    refetch({
      id,
      type,
      locality: LOCALITY,
    });
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  return (
    <>
      <ModalPageLoadingAiNext
        is_open={isError}
        title={errorTitle}
        statusModal="alert"
        titleBtn="Ok"
        onRefresh={() => {
          setIsError(false);
        }}
      />

      <ModalDelete
        is_open={isOpenDelete}
        api_url={`baseinformation/delete/${idCard}`}
        title="Deletar documento"
        description="Deseja deletar o documento?"
        onClose={() => {
          setIsOpenDelete(false);
          setIdCard("");
        }}
        onCloseSend={() => {
          setIsOpenDelete(false);
          setIdCard("");
          getData();
        }}
      />

      <Flex flexDirection="column" mt="5px">
        {loading && <BoxLoading />}
        <Box>
          <ButtonBaseInformationAdd
            isLoading={loadingSend}
            title="Novo documento"
            onPress={() => {
              open();
            }}
          />
        </Box>
        <Flex mt="25px">
          <Wrap spacing="30px">
            {!loading &&
              dataList &&
              dataList.map((item, _) => (
                <WrapItem key={item.id}>
                  <Flex
                    width="195px"
                    height="153px"
                    borderWidth="1px"
                    borderColor="#E5E4E4"
                    borderRadius="10px"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    gap="10px"
                    cursor="pointer"
                    onMouseOver={() => {
                      setSelectedItem(item.id);
                    }}
                    onMouseOut={() => {
                      setSelectedItem("");
                    }}
                  >
                    <Icon
                      as={IconBoxDocumentBaseInformation}
                      fontSize="18px"
                      color="blue.400"
                    />
                    <Text color="#3A3A3A" textAlign="center" fontWeight={500}>
                      {item.title}
                    </Text>
                    {selectedItem === item.id && (
                      <Flex
                        gap="5px"
                        cursor="pointer"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => {
                          deleteData(item.id);
                        }}
                      >
                        <Icon
                          as={RiDeleteBin5Line}
                          fontSize="14px"
                          color="#FF5656"
                        />
                        <Text color="#FF5656" fontWeight="bold">
                          Excluir
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </WrapItem>
              ))}
          </Wrap>
        </Flex>
      </Flex>
    </>
  );
}
