import { useEffect, useState } from "react";
import { useListPageData } from "../../hooks/useListPageData";
import {
  Button,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { BoxLoading } from "../../../../../components/boxLoading";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { api } from "../../../../../service/api";
import EditorV2 from "../../../../../components/editorV2";
import { useListPagesData } from "../../hooks/useListPagesData";
import { ModalPageLoadingAiNext } from "../../../../../components/modalPageLoadingAiNext";
import { useNavigate } from "react-router-dom";
import { useBiographyContext } from "../../../../../hooks/contexts/biography/biography.context";
import { statusModalPageLoadingAiNext } from "../../../../../components/modalPageLoadingAiNext/ModalPageLoadingAiNext.types";

type Props = {
  id: string;
  page_id: string;
  chapter_id: string;
};
export function PageData({ id, page_id, chapter_id }: Props) {
  const toast = useToast();
  const navigate = useNavigate();

  const { getMenuBook } = useBiographyContext();

  const [loadingPageSend, setLoadingPageSend] = useState(false);
  const { dataList, refetch, loading } = useListPageData();
  const { dataListPage, refetchPage, loadingPage } = useListPagesData();
  const [loadingSendFinalize, setLoadingSendFinalize] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [statusModal, setStatusModal] =
    useState<statusModalPageLoadingAiNext>("not_started");

  useEffect(() => {
    refetch({ id: page_id });
    refetchPage({ chapter_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page_id, chapter_id]);

  async function sendData(content: string) {
    try {
      setIsLoadingSave(true);
      await api.put(`book/page/update/${page_id}`, {
        content,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Remover novo pagina",
        description: "Não foi possível atualizar página.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoadingSave(false);
    }
  }

  // async function sendDelete() {
  //   try {
  //     setLoadingSend(true);
  //     await api.delete(`book/page/delete/${page_id}`);
  //     window.location.reload();
  //   } catch (error) {
  //     toast({
  //       position: "top-right",
  //       title: "Remover novo pagina",
  //       description: "Não foi possível remover pagina.",
  //       status: "error",
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //   } finally {
  //     setLoadingSend(false);
  //   }
  // }

  async function createPages() {
    try {
      setLoadingPageSend(true);
      setStatusModal("processing");
      setIsOpenModalStatus(true);
      await api.post("book/page/create", {
        title: "news pages",
        book_id: chapter_id,
      });
      refetch({ id: page_id });
      refetchPage({ chapter_id });
      getMenuBook({ id, category_name: "chapter" });
      setIsOpenModalStatus(false);
    } catch (error) {
      setIsOpenModalStatus(false);
      setStatusModal("error");
      setIsOpenModalStatus(true);
    } finally {
      setLoadingPageSend(false);
    }
  }

  async function onFinalize() {
    try {
      setLoadingSendFinalize(true);
      await api.put(`book/page/finalize/${page_id}`, {
        biography_id: id,
        chapter_id,
      });
      getMenuBook({ id, category_name: "chapter" });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Finalizar página",
        description: "Não foi possível finalizar página.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingSendFinalize(false);
    }
  }

  return (
    <>
      <ModalPageLoadingAiNext
        is_open={isOpenModalStatus}
        title={
          statusModal === "processing"
            ? "A IA está analisando suas informações.."
            : "Não foi possível analisar suas informações.."
        }
        description={
          statusModal === "processing"
            ? "Isso pode levar algum tempo."
            : "Você pode tentar novamente"
        }
        children={
          <Flex width="230px" margin="auto" marginBottom="20px">
            <UnorderedList>
              <ListItem fontSize="14px">Base de conhecimento.</ListItem>
            </UnorderedList>
          </Flex>
        }
        statusModal={statusModal}
        titleBtn="Tentar novamente"
        onRefresh={() => {
          createPages();
        }}
        onClonse={() => {
          setIsOpenModalStatus(false);
          setStatusModal("done");
        }}
      />
      {(loading || loadingPage) && (
        <Flex height="70vh" width="100%" position="relative">
          <BoxLoading />
        </Flex>
      )}

      {!loading &&
        !loadingPage &&
        page_id === "empty" &&
        dataListPage.length === 0 && (
          <Button
            isLoading={loadingPageSend}
            type="button"
            bg="blue.400"
            color="white"
            style={{
              borderRadius: 10,
              height: 44,
              fontSize: 16,
              fontWeight: 600,
            }}
            _hover={{
              bg: "blue.400",
            }}
            size="lg"
            cursor="pointer"
            onClick={() => {
              createPages();
            }}
          >
            Criar páginas
          </Button>
        )}

      <Flex flexDirection="column">
        {!loading &&
          !loadingPage &&
          page_id === "empty" &&
          dataListPage.length > 0 && <Text mb="20px">Páginas</Text>}

        {!loading &&
          !loadingPage &&
          page_id === "empty" &&
          dataListPage.map((itemPage, index) => (
            <Flex
              marginBottom="10px"
              width="100%"
              minWidth="300px"
              height="45px"
              borderRadius="10px"
              borderWidth="1px"
              borderColor="gray.400"
              px="10px"
              alignItems="center"
              justifyContent="space-between"
              cursor={itemPage.status === "created" ? "no-drop" : "pointer"}
              _hover={{
                borderWidth: 1,
                borderColor:
                  itemPage.status === "created" ? "gray.400" : "#36A9E1",
              }}
              onClick={() => {
                if (itemPage.status !== "created") {
                  navigate(
                    `/biografia/meu-livro/${id}?type=chapter&chapter=${chapter_id}&page=${itemPage.id}`
                  );
                }
              }}
            >
              <Text
                color={itemPage.status === "created" ? "gray.400" : "black"}
              >{`Página ${index + 1}`}</Text>
            </Flex>
          ))}
      </Flex>

      {!loading && !loadingPage && dataList.id && page_id !== "empty" && (
        <Flex flexDirection="column">
          <Flex>
            <EditorV2
              isLoading={loadingSendFinalize}
              isLoadingSave={isLoadingSave}
              content={dataList.content}
              onFinalize={() => {
                onFinalize();
              }}
              onRewrite={() => {}}
              onSave={(text) => {
                sendData(text);
              }}
              urlApi={`book/ia/generete/text/${page_id}`}
              dataApi={{
                localtion: "page",
                biography_id: id,
              }}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
}
