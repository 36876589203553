import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import Logo from "../../../../assets/logo.png";
import { useSearchParams } from "react-router-dom";

const results = [
  { type: "sucesso", text: "Assinatura criada com sucesso" },
  { type: "erro", text: "Não foi possível criar assinatura" },
  { type: "pendente", text: "Assinatura em andamento" },
];

export function PaymentCallback() {
  const [searchParams] = useSearchParams();

  const type = searchParams.get("type") ?? "";

  return (
    <Flex
      w="100vw"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      px={40}
      py={20}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width={400}
      >
        <Image src={Logo} width={400} alt="Logo" />

        <Text mt={5} textAlign="center" fontSize="30px" color="gray.800">
          {results.find((item) => item.type === type)?.text}
        </Text>

        {type === "sucesso" && (
          <Flex mt={5} alignItems="center" justifyContent="center">
            <Link href="/login" color="green.500" fontWeight="bold">
              Realizar login
            </Link>
          </Flex>
        )}
        {type !== "sucesso" && (
          <Flex mt={5} alignItems="center" justifyContent="center">
            <Link href="/login" color="green.500" fontWeight="bold">
              Entrar em contato
            </Link>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
