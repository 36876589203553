export const dataQuestions = [
  {
    id: "1",
    type: "infancy",
    questions: [
      {
        id: "1",
        description: "Quando e onde você nasceu?",
      },
      {
        id: "2",
        description:
          "Você se lembra de alguma história interessante sobre o seu nascimento?",
      },
      {
        id: "3",
        description: "Qual é sua primeira memória?",
      },
      {
        id: "4",
        description: "Quem foi a pessoa mais influente para você na infância?",
      },
      {
        id: "5",
        description:
          "Que adjetivos você usaria para descrever sua infância? Por quê?",
      },
      {
        id: "6",
        description:
          "Como seus pais descreveriam você quando criança? Por quê?",
      },
      {
        id: "7",
        description:
          "Você tinha algum animal de estimação quando criança? Que tipo?",
      },
      {
        id: "8",
        description: "O que você fazia durante as férias de verão?",
      },
      {
        id: "9",
        description: "Quais eram os seus jogos ou esportes favoritos?",
      },
      {
        id: "10",
        description:
          "Quem foi seu primeiro melhor amigo? Você tinha outros amigos com quem convivia com frequência?",
      },
      {
        id: "11",
        description:
          "Você tinha algum apelido? Elabore sobre ele e sobre os apelidos dentro da família.",
      },
      {
        id: "12",
        description: "Do que você mais tinha medo quando criança?",
      },
      {
        id: "13",
        description:
          "Você se lembra de alguma história interessante contada por algum parente mais velho que você nunca esqueceu e que acha que vale a pena contar?",
      },
      {
        id: "14",
        description:
          "Fale sobre seus irmãos, primos e outros parentes próximos, e como era seu relacionamento com eles.",
      },
      {
        id: "15",
        description: "Descreva seus pais. Como eles eram? O que eles faziam?",
      },
      {
        id: "16",
        description:
          "Onde seu pai trabalhava? Qual é a imagem mais forte que você tem dele?",
      },
      {
        id: "17",
        description:
          "Onde sua mãe trabalhava? Qual é a imagem mais forte que você tem dela?",
      },
      {
        id: "18",
        description: "Descreva seus avós. O que você mais gostava neles?",
      },
      {
        id: "19",
        description:
          "Você viveu em mais de uma casa ou cidade? Quais memórias são marcantes desses lugares?",
      },
      {
        id: "20",
        description:
          "Como você viveu a sua infância no bairro em que cresceu? Quais brincadeiras coloriam seu dia a dia?",
      },
    ],
  },
  {
    id: "2",
    type: "family",
    questions: [
      {
        id: "1",
        description:
          "Você tem filhos? Se sim, quantos e quais são as idades e sexos?",
      },
      {
        id: "2",
        description: "Qual é o seu objetivo como pai ou mãe?",
      },
      {
        id: "3",
        description: "O que a palavra 'família' significa para você?",
      },
      {
        id: "4",
        description:
          "Você tem algum arrependimento em relação à forma como criou seus filhos?",
      },
      {
        id: "5",
        description: "De que maneira você influenciou mais seus filhos?",
      },
      {
        id: "6",
        description: "Como seus filhos descreveriam você como pai ou mãe?",
      },
      {
        id: "7",
        description: "Como você se descreve como pai ou mãe?",
      },
      {
        id: "8",
        description: "De que maneiras seus pais te influenciaram mais?",
      },
      {
        id: "9",
        description: "Com qual dos seus pais você mais se parece e por quê?",
      },
      {
        id: "10",
        description: "Você gostaria de ter tido uma criação diferente? Como?",
      },
      {
        id: "11",
        description: "Como é seu relacionamento com seus pais hoje?",
      },
      {
        id: "12",
        description: "Qual é o seu papel na família?",
      },
      {
        id: "13",
        description:
          "De que forma a maneira como você foi criado(a) afetou a forma como você criou seus próprios filhos?",
      },
      {
        id: "14",
        description:
          "Você tem irmãos? Quantos, eles são mais velhos ou mais novos, e quais são os sexos?",
      },
      {
        id: "15",
        description: "Como são seus relacionamentos com seus irmãos?",
      },
      {
        id: "16",
        description:
          "Você mudaria algo em relação aos seus relacionamentos com eles?",
      },
      {
        id: "17",
        description: "Quem é a pessoa mais próxima de você na sua família?",
      },
      {
        id: "18",
        description: "Quem você mais admira na sua família e por quê?",
      },
      {
        id: "19",
        description:
          "Você foi criado(a) em uma família desfeita? Se sim, por quem você foi criado(a) e como isso o(a) afetou?",
      },
      {
        id: "20",
        description:
          "Você perdeu algum membro da família por morte inesperada? Se sim, qual era o seu relacionamento com eles e como a morte deles o(a)/sua família afetou?",
      },
      {
        id: "21",
        description:
          "Sua família tinha algumas tradições únicas? De que forma isso contribuiu com a sua memória afetiva?",
      },
      {
        id: "22",
        description:
          "Você e sua família dividem alguma piada interna ou lembrança divertida? Quais?",
      },
    ],
  },
  {
    id: "3",
    type: "memories-of-school-and-high-school",
    questions: [
      {
        id: "1",
        description:
          "Onde você estudou primeiro? Ainda guarda lembranças do lugar e de algum professor?",
      },
      {
        id: "2",
        description:
          "Você se lembra do seu período de alfabetização? Seus pais te contaram alguma coisa sobre isso, ou ainda podem contar?",
      },
      {
        id: "3",
        description: "Onde você estudou no ensino médio?",
      },
      {
        id: "4",
        description:
          "Quem era seu melhor amigo? Você ainda mantém contato com ele(a)?",
      },
      {
        id: "5",
        description: "Qual era sua matéria favorita para estudar?",
      },
      {
        id: "6",
        description: "Qual era a matéria que você menos gostava? Por quê?",
      },
      {
        id: "7",
        description: "Você participou de algum time esportivo?",
      },
      {
        id: "8",
        description: "Qual era seu esporte favorito nesse período?",
      },
      {
        id: "9",
        description:
          "Você participava de algum clube especial dentro da escola?",
      },
      {
        id: "10",
        description: "Você fez parte do grêmio estudantil?",
      },
      {
        id: "11",
        description:
          "Algum professor foi particularmente influente para você? Por quê?",
      },
      {
        id: "12",
        description:
          "Como você se descreveria como aluno, tanto socialmente quanto academicamente?",
      },
      {
        id: "13",
        description:
          "O que você mais gostava na escola? E o que menos gostava?",
      },
      {
        id: "14",
        description:
          "Em que tipo de atividades extracurriculares você participou na adolescência?",
      },
      {
        id: "15",
        description:
          "Você ganhou algum prêmio ou foi reconhecido por algum feito?",
      },
      {
        id: "16",
        description:
          "Você tinha algum ídolo adolescente? Se sim, quem era e como ele(a) despertou seu interesse?",
      },
      {
        id: "17",
        description: "Quem foi seu primeiro relacionamento?",
      },
      {
        id: "18",
        description: "Que tipo de música você escutava?",
      },
      {
        id: "19",
        description:
          "Qual era sua música, banda e dança favorita no ensino médio?",
      },
      {
        id: "20",
        description:
          "O que as pessoas que você conhece hoje achariam surpreendente sobre você na adolescência?",
      },
    ],
  },
  {
    id: "4",
    type: "college-and-early-adulthood",
    questions: [
      {
        id: "1",
        description:
          "Você frequentou a faculdade? Se sim, qual? Se não, por quê?",
      },
      {
        id: "2",
        description: "O que te motivava a frequentar a faculdade?",
      },
      {
        id: "3",
        description: "Você pagou seus estudos ou foi apoiado pelos seus pais?",
      },
      {
        id: "4",
        description: "Você morava em um dormitório?",
      },
      {
        id: "5",
        description:
          "Quem foi seu colega de quarto mais memorável? Você ainda tem contato com alguns desses colegas?",
      },
      {
        id: "6",
        description: "Por que você decidiu frequentar a faculdade?",
      },
      {
        id: "7",
        description: "Que matérias você estudou e por quê?",
      },
      {
        id: "8",
        description: "Você se formou? Em quê?",
      },
      {
        id: "9",
        description:
          "Quantas gerações da sua família receberam um diploma antes de você?",
      },
      {
        id: "10",
        description:
          "Sua família apoiou sua decisão de ir para a faculdade? Como?",
      },
      {
        id: "11",
        description:
          "Como sua experiência universitária o preparou para os anos seguintes?",
      },
      {
        id: "12",
        description:
          "Se você pudesse fazer tudo de novo, escolheria um caminho acadêmico diferente ou está satisfeito com a rota que seguiu?",
      },
      {
        id: "13",
        description:
          "De que forma a faculdade moldou seu repertório e seus interesses hoje?",
      },
      {
        id: "14",
        description:
          "Você sempre sonhou em fazer esse curso, ou foi uma decisão que levou tempo até você amadurecer essa ideia?",
      },
      {
        id: "15",
        description:
          "Você fez um diploma avançado? Se sim, qual foi, e você o utilizou em sua carreira profissional?",
      },
      {
        id: "16",
        description: "Em quais atividades no campus você participou?",
      },
    ],
  },
  {
    id: "5",
    type: "career",
    questions: [
      {
        id: "1",
        description: "Qual foi o seu primeiro emprego?",
      },
      {
        id: "2",
        description: "Qual foi o seu melhor emprego?",
      },
      {
        id: "3",
        description: "Qual foi o seu pior emprego?",
      },
      {
        id: "4",
        description:
          "Qual foi o maior tempo que você já permaneceu em uma empresa? Qual era sua posição lá?",
      },
      {
        id: "5",
        description:
          "Além de receber dinheiro, de que outra forma sua carreira agregou valor à sua vida?",
      },
      {
        id: "6",
        description:
          "Você tem algum arrependimento em relação à sua trajetória profissional?",
      },
      {
        id: "7",
        description:
          "Qual é a sua conquista mais significativa como profissional?",
      },
      {
        id: "8",
        description: "Você já foi demitido por razões injustas?",
      },
      {
        id: "9",
        description:
          "O que você considera ser o melhor ambiente de trabalho possível?",
      },
      {
        id: "10",
        description: "Quem foi a maior influência na sua carreira?",
      },
      {
        id: "11",
        description:
          "Quem foi o seu melhor chefe e por que você admirava a sua liderança?",
      },
      {
        id: "12",
        description:
          "Você já liderou uma equipe? Quais foram os maiores desafios e aprendizados?",
      },
      {
        id: "13",
        description: "Qual seria o seu trabalho ideal?",
      },
      {
        id: "14",
        description:
          "Conte aqui dois ou três desafios profissionais que você teve, e como você os solucionou.",
      },
    ],
  },
  {
    id: "6",
    type: "love-life",
    questions: [
      {
        id: "1",
        description: "Quem foi seu primeiro amor?",
      },
      {
        id: "2",
        description: "Você já teve o coração partido?",
      },
      {
        id: "3",
        description: "Você já partiu o coração de alguém?",
      },
      {
        id: "4",
        description: "Você já foi casado(a)? Com quem e por quanto tempo?",
      },
      {
        id: "5",
        description: "Descreva como você é como namorado(a).",
      },
      {
        id: "6",
        description: "Como você e seu cônjuge se conhecueram?",
      },
      {
        id: "7",
        description: "Como você propôs e/ou aceitou o pedido de união?",
      },
      {
        id: "8",
        description: "Descreva seu casamento e sua lua de mel.",
      },
      {
        id: "9",
        description: "Você acredita em amor à primeira vista?",
      },
      {
        id: "10",
        description: "Você já se divorciou?",
      },
      {
        id: "11",
        description: "Descreva o seu pior término de relacionamento.",
      },
      {
        id: "12",
        description: "Quem foi a sua maior paixão?",
      },
      {
        id: "13",
        description: "Qual é o seu papel no casamento ou relacionamentos?",
      },
      {
        id: "14",
        description:
          "Você tem algum arrependimento em relação à sua vida amorosa?",
      },
      {
        id: "15",
        description:
          "Qual foi a maneira mais especial que você demonstrou a alguém que o amava?",
      },
      {
        id: "16",
        description:
          "Qual foi a maneira mais especial que alguém demonstrou que te amava?",
      },
    ],
  },
  {
    id: "7",
    type: "political-and-social-causes",
    questions: [
      {
        id: "1",
        description:
          "Qual você considera ser o evento político mais significativo que ocorreu durante sua vida?",
      },
      {
        id: "2",
        description:
          "Qual você considera ser o evento político mais significativo da história da humanidade?",
      },
      {
        id: "3",
        description: "Qual figura política você mais admira? Por quê?",
      },
      {
        id: "4",
        description: "Qual figura política você menos admira? Por quê?",
      },
      {
        id: "5",
        description:
          "Além do presente, em qual era histórica você gostaria de ter vivido?",
      },
      {
        id: "6",
        description:
          "Qual você considera ser a guerra mais importante que foi travada durante sua vida? E em toda a história?",
      },
      {
        id: "7",
        description: "Qual guerra você considera ser a menos importante?",
      },
      {
        id: "8",
        description: "Você já lutou por uma causa política?",
      },
      {
        id: "9",
        description:
          "Quais movimentos sociais afetaram sua consciência social e como?",
      },
      {
        id: "10",
        description:
          "O que você gostaria de mudar no atual cenário político/social?",
      },
      {
        id: "11",
        description: "Você é politicamente ativo(a)?",
      },
      {
        id: "12",
        description:
          "Se tivesse que escolher, por qual causa política/social você daria sua vida?",
      },
      {
        id: "13",
        description:
          "Se pudesse conhecer qualquer ícone histórico, do passado ou do presente, quem seria e por quê?",
      },
    ],
  },
  {
    id: "8",
    type: "general-topics",
    questions: [
      {
        id: "1",
        description: "Qual é a sua definição de 'felicidade'?",
      },
      {
        id: "2",
        description: "Qual foi o seu pior desapontamento?",
      },
      {
        id: "3",
        description: "Qual celebridade você mais gostaria de conhecer?",
      },
      {
        id: "4",
        description: "Qual foi a sua experiência de viagem mais memorável?",
      },
      {
        id: "5",
        description: "Qual é o lugar mais distante de casa que você já esteve?",
      },
      {
        id: "6",
        description:
          "Qual foi a coisa mais engraçada que já aconteceu com você?",
      },
      {
        id: "7",
        description: "Qual foi o seu momento mais rebelde?",
      },
      {
        id: "8",
        description:
          "Se tivesse que escolher, qual é a única coisa de que você se arrepende na maneira como viveu sua vida?",
      },
      {
        id: "9",
        description: "Qual é a sua memória mais feliz?",
      },
      {
        id: "10",
        description: "E a mais triste?",
      },
      {
        id: "11",
        description: "De qual conquista você mais se orgulha?",
      },
      {
        id: "12",
        description: "Se pudesse fazer tudo de novo, o que faria de diferente?",
      },
      {
        id: "13",
        description: "Como espera ser lembrado?",
      },
      {
        id: "14",
        description: "Como acha que as pessoas vão te lembrar?",
      },
      {
        id: "15",
        description: "Quem é o seu maior fã?",
      },
      {
        id: "16",
        description: "De quem você é o maior fã?",
      },
      {
        id: "17",
        description: "O que você gosta de fazer no seu tempo livre?",
      },
      {
        id: "18",
        description:
          "Qual é a única pergunta à qual você sempre responde com uma mentira?",
      },
    ],
  },
];
