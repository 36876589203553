export function IconPdfPrint() {
  return (
    <svg
      width={31}
      height={32}
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.305 3.567v5.526h1.797V1.819h-.07c-.953 0-1.727.783-1.727 1.748zM23.898 1.82v7.274h1.797V3.567c0-.965-.774-1.748-1.728-1.748h-.069zM9.867 0c-.954 0-1.728.783-1.728 1.749v7.344H22.86V1.749C22.861.783 22.087 0 21.133 0H9.867zM1.728 25.95h5.55v-3.938H5.844a.57.57 0 01-.567-.574c0-.314.256-.573.567-.573h19.312a.57.57 0 01.567.573.573.573 0 01-.567.574h-1.434v3.938h5.55c.954 0 1.728-.784 1.728-1.749v-12.31c0-.966-.774-1.749-1.728-1.749H1.728c-.954 0-1.728.784-1.728 1.749V24.2c0 .965.774 1.749 1.728 1.749zm19.775-12.426a.87.87 0 01.864-.874h3.328a.87.87 0 01.843.874v.63a.87.87 0 01-.864.874h-3.307a.856.856 0 01-.632-.28.884.884 0 01-.232-.595v-.63z"
        fill="#36A9E1"
      />
      <path
        d="M21.133 32c.954 0 1.728-.783 1.728-1.749v-8.24H8.14v8.24c0 .966.774 1.749 1.728 1.749h11.266zm-8.688-8.026h6.113a.57.57 0 01.567.573.573.573 0 01-.567.574h-6.113a.57.57 0 01-.567-.574.57.57 0 01.567-.573zm0 2.805h6.113a.575.575 0 01.004 1.147h-6.117a.57.57 0 01-.567-.574.574.574 0 01.567-.573z"
        fill="#36A9E1"
      />
    </svg>
  );
}
