import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { BotMessageSquare } from "lucide-react";

type Props = {
  onCloseSend: (type: "replace" | "continue") => void;
};

export function PopoverIa({ onCloseSend }: Props) {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover
      placement="bottom"
      closeOnBlur={true}
      onOpen={onOpen}
      onClose={onClose}
      isOpen={isOpen}
    >
      <PopoverTrigger>
        <BotMessageSquare />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          Ia Oca books
        </PopoverHeader>
        <PopoverArrow bg="white" />
        <PopoverCloseButton />
        <PopoverFooter
          border="0"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
        >
          <ButtonGroup size="sm" margin="auto">
            <Button
              colorScheme="blue"
              onClick={() => {
                onCloseSend("replace");
                onClose();
              }}
            >
              Atualizar texto
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => {
                onCloseSend("continue");
                onClose();
              }}
            >
              Continuar texto
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
