import { Flex, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  icon: any;
  onPress: () => void;
  bgColor?: string;
  textColor?: string;
};

export function ButtonNewBook({
  title,
  icon,
  onPress,
  bgColor = "blue.400",
  textColor = "#FFFFFF",
}: Props) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      backgroundColor={bgColor}
      px="20px"
      height="43px"
      borderRadius="50px"
      cursor="pointer"
      as="button"
      onClick={onPress}
    >
      <Text style={{ fontWeight: 400, fontSize: 18, color: textColor }}>
        {title}
      </Text>
      <Flex ml="5px">{icon}</Flex>
    </Flex>
  );
}
