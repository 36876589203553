import { Button, Icon } from "@chakra-ui/react";
import { RiAddCircleFill } from "react-icons/ri";

type Props = {
  title: string;
  onPress: () => void;
  isLoading?: boolean;
  icon?: any;
  isBtnBook?: boolean;
};

export function ButtomNewChapterOrPage({
  title,
  onPress,
  isLoading = false,
  icon = RiAddCircleFill,
  isBtnBook = false,
}: Props) {
  return (
    <Button
      type="button"
      bg={isBtnBook ? "blue.400" : "transparent"}
      color={isBtnBook ? "white" : "blue.400"}
      width="100%"
      style={{
        borderRadius: 50,
        height: 44,
        fontSize: 16,
        fontWeight: 600,
      }}
      _hover={{
        bg: isBtnBook ? "blue.400" : "transparent",
        borderWidth: isBtnBook ? 0 : 1,
        borderColor: isBtnBook ? "blue.400" : "blue.400",
      }}
      size="lg"
      isLoading={isLoading}
      rightIcon={
        <Icon
          as={icon}
          fontSize="18px"
          color={isBtnBook ? "white" : "blue.400"}
        />
      }
      onClick={onPress}
    >
      {title}
    </Button>
  );
}
