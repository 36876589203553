import { Button, Flex } from "@chakra-ui/react";

type Props = {
  onCancelPlan: () => void;
};

export function ButtonSubscriptionPro({ onCancelPlan }: Props) {
  return (
    <Flex alignItems="center" justifyContent="space-between" width="100%">
      <Button
        type="button"
        bg="#1E1E1E"
        color="white"
        width="100%"
        style={{
          borderRadius: 5,
          height: 39,
          width: 161,
          fontSize: 16,
          fontWeight: 600,
        }}
        _hover={{
          bg: "#1E1E1E",
        }}
        size="lg"
        as="a"
        href="/planos"
      >
        Fazer upgrade
      </Button>

      {/* <Button
        type="button"
        bg="white"
        color="#A1A1A1"
        width="100%"
        borderColor="#A1A1A1"
        borderWidth={1}
        style={{
          borderRadius: 5,
          height: 39,
          width: 165,
          fontSize: 16,
          fontWeight: 600,
        }}
        _hover={{
          bg: "white",
        }}
        size="lg"
        onClick={onCancelPlan}
      >
        Cancelar plano
      </Button> */}
    </Flex>
  );
}
