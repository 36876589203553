import { useState } from "react";
import { api } from "../../../../service/api";
import { PageDataDTO } from "../../../../dtos/PageDataDTO";

type Props = {
  chapter_id: string;
};

export function useListPagesData() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [errorPage, setErrorPage] = useState<boolean | null>(null);
  const [dataListPage, setDataListPage] = useState<PageDataDTO[]>([]);

  const fetchData = async ({ chapter_id }: Props) => {
    try {
      setLoadingPage(true);
      const response = await api.get(`book/page/list/${chapter_id}`);
      setDataListPage(response.data);
    } catch (error) {
      setErrorPage(true);
    } finally {
      setLoadingPage(false);
    }
  };

  return {
    dataListPage,
    loadingPage,
    errorPage,
    refetchPage: fetchData,
  };
}
