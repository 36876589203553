import { useEffect, useState } from "react";
import { api } from "../../../../service/api";

export function useStatusMessageBot(id?: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [statusMessage, setStatusMessage] = useState<boolean>(false);

  const fetchData = async (id_bio?: string): Promise<boolean | undefined> => {
    try {
      setLoading(true);
      const response = await api.get(`chatbot/message/block/${id_bio}`);
      setStatusMessage(response.data.chat_finished);
      return response.data.chat_finished;
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    statusMessage,
    loading,
    error,
    refetch: fetchData,
  };
}
