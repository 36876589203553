export function IconMenuOptionBook() {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 0h.5v9.5h-.5c-3.525 0-5.445 0-6.5.6V.6C9.055 0 10.975 0 14.5 0zm-4.45 7.6c.64-.065 1.445-.085 2.455-.095l-.01-1c-1.035.01-1.865.03-2.545.1l.1.995zm0-2c.64-.065 1.445-.085 2.455-.095l-.01-1c-1.035.01-1.865.03-2.545.1l.1.995zm0-2c.64-.065 1.445-.085 2.455-.095l-.01-1c-1.035.01-1.865.03-2.545.1l.1.995zM.5 0C4.025 0 5.945 0 7 .6v9.5c-1.055-.6-2.975-.6-6.5-.6H0V0h.5zm4.45 7.6l.1-.995c-.68-.07-1.51-.09-2.545-.1l-.01 1c1.01.01 1.815.03 2.455.095zm0-2l.1-.995c-.68-.07-1.51-.09-2.545-.1l-.01 1c1.01.01 1.815.03 2.455.095zm0-2l.1-.995c-.68-.07-1.51-.09-2.545-.1l-.01 1c1.01.01 1.815.03 2.455.095zm.5 7.215l-.43 1.295c.115.015.215.03.3.045.245.32.855.845 2.17.845s1.935-.53 2.185-.845C10.445 12 12.59 12 14.5 12h.5v-1.5h-.5c-4.965 0-6.155.04-6.525 1.16h-.95c-.185-.56-.575-.85-1.535-1-.01.05-.02.1-.04.155zM0 14l1 1 1-1 1 1 1.5-4.5L0 12l1 1-1 1z"
        fill="#000"
      />
    </svg>
  );
}
