export function IconAudioBaseInformation() {
  return (
    <svg
      width={12}
      height={17}
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7.968V6.375a.524.524 0 00-.16-.376.553.553 0 00-.771 0 .524.524 0 00-.16.376v1.593c0 2.637-2.202 4.781-4.909 4.781s-4.91-2.144-4.91-4.78V6.374a.524.524 0 00-.159-.376.553.553 0 00-.771 0 .524.524 0 00-.16.376v1.593c0 3.043 2.401 5.547 5.455 5.817v2.152H2.182a.553.553 0 00-.386.156.524.524 0 000 .751c.102.1.241.156.386.156h7.636a.553.553 0 00.386-.156.524.524 0 000-.75.553.553 0 00-.386-.157H6.545v-2.152C9.6 13.515 12 11.01 12 7.968z"
        fill="#36A9E1"
      />
      <path
        d="M6 11.156c1.805 0 3.273-1.43 3.273-3.187V3.187C9.273 1.43 7.805 0 6 0 4.195 0 2.727 1.43 2.727 3.188v4.78c0 1.759 1.468 3.188 3.273 3.188zM3.818 3.187c0-1.171.979-2.124 2.182-2.124 1.203 0 2.182.953 2.182 2.125v4.78c0 1.173-.979 2.126-2.182 2.126-1.203 0-2.182-.953-2.182-2.125V3.187z"
        fill="#36A9E1"
      />
    </svg>
  );
}
