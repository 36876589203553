export function IconTextBaseInformation() {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.125.5H1.875A1.875 1.875 0 000 2.375v12.25A1.875 1.875 0 001.875 16.5h12.25A1.875 1.875 0 0016 14.625V2.375A1.875 1.875 0 0014.125.5zM15 14.625a.876.876 0 01-.875.875H1.875A.876.876 0 011 14.625V2.375c0-.482.393-.875.875-.875h12.25c.482 0 .875.393.875.875v12.25z"
        fill="#36A9E1"
      />
      <path
        d="M13 3.482H7.428a.5.5 0 100 1H13a.5.5 0 000-1zM3 7.386a.5.5 0 00.5-.5V5.694h1.2v1.194a.5.5 0 101 0V4.721C5.7 3.772 4.982 3 4.1 3c-.882 0-1.6.772-1.6 1.722v2.165a.5.5 0 00.5.5zM4.1 4c.323 0 .585.309.598.693H3.502C3.516 4.308 3.779 4 4.1 4zM13 5.862H7.428a.5.5 0 100 1H13a.5.5 0 000-1zm0 2.379H3a.5.5 0 100 1h10a.5.5 0 000-1zm0 2.38H3a.5.5 0 000 1h10a.5.5 0 000-1zM13 13H3a.5.5 0 000 1h10a.5.5 0 000-1z"
        fill="#36A9E1"
      />
    </svg>
  );
}
