import { Flex, Text } from "@chakra-ui/react";
import { Title } from "../../components/Title";
import { dateTimerToDate } from "../../utils/formatters";
import { useAuth } from "../../hooks/useAuth";
import { ButtonSubscriptionPro } from "./components/buttonSubscriptionPro";

export function Subscription() {
  const { user } = useAuth();

  return (
    <Flex w="100%" marginTop="41px" marginBottom="50px" flexDirection="column">
      <Title title="Meu Plano" />
      <Flex
        marginTop="41px"
        width={["100%", "100%", "1157px"]}
        height="266px"
        borderWidth={1}
        borderColor="#D9D9D9"
        backgroundColor="#FDFDFD"
        flexDirection="column"
        padding="38px"
      >
        <Flex borderRadius="15px" flexDirection="column" flex={1}>
          <Text color="#1E1E1E" fontSize="16px" fontWeight={500}>
            Plano
          </Text>

          <Flex mt="10px" alignItems="center" gap={2}>
            <Text color="#1E1E1E" fontSize="24px" fontWeight={500}>
              {user.subscription?.type === "pro"
                ? " Escritor pro PRO"
                : "Escritor Basic"}
            </Text>
            <Flex
              width="50px"
              backgroundColor="#D9D9D9"
              borderRadius="5px"
              height="27px"
              alignItems="center"
              justifyContent="center"
            >
              <Text color="#1E1E1E" fontSize="12px" fontWeight={600}>
                {user.subscription?.type === "pro" ? " PRO" : "FREE"}
              </Text>
            </Flex>
          </Flex>

          <Text mt="20px" color="#3A3A3A" fontSize="16px" fontWeight={400}>
            Próxima renovação automática de créditos em{" "}
            {dateTimerToDate(
              user.subscription ? user.subscription.current_period_end : ""
            )}
          </Text>
        </Flex>

        <Flex>
          <ButtonSubscriptionPro onCancelPlan={() => {}} />
        </Flex>
      </Flex>
    </Flex>
  );
}
