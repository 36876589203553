import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { RiArrowRightSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { typesBaseInformation } from "../../../../../data/data";
import { Rows4, X } from "lucide-react";

const linksMenu = typesBaseInformation;

type Props = {
  id: string;
  pageType: string;
};

export function BaseInformationMenu({ id, pageType }: Props) {
  const navigate = useNavigate();

  const {
    isOpen: isOpenDraw,
    onOpen: openDraw,
    onClose: onCloseDraw,
  } = useDisclosure();

  return (
    <>
      <IconButton
        position="absolute"
        aria-label="Search database"
        icon={<Rows4 />}
        right="10px"
        top="115px"
        variant="outline"
        display={["flex", "flex", "none"]}
        onClick={openDraw}
      />

      <Drawer
        placement={"right"}
        size="full"
        onClose={onCloseDraw}
        isOpen={isOpenDraw}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <IconButton
              mt="10px"
              aria-label="close menu"
              onClick={onCloseDraw}
              display={["flex", "flex", "none"]}
              variant="outline"
              icon={<X />}
            />
          </DrawerHeader>
          <DrawerBody>
            <Flex gap="20px" flexDirection="column">
              {linksMenu.map((item, _) => (
                <Flex
                  key={item.id}
                  width="100%"
                  height="41px"
                  backgroundColor={
                    pageType === item.type ? "blue.400" : "transparent"
                  }
                  borderRadius="5px"
                  px="10px"
                  alignItems="center"
                  justifyContent="space-between"
                  cursor="pointer"
                  onClick={() => {
                    onCloseDraw();
                    navigate(
                      `/biografia/base-conhecimento/${id}?type=${item.type}`
                    );
                  }}
                >
                  <Text
                    color={pageType === item.type ? "white" : "black"}
                    fontWeight="bold"
                  >
                    {item.title}
                  </Text>
                  <Icon
                    as={RiArrowRightSLine}
                    fontSize="18px"
                    color={pageType === item.type ? "white" : "black"}
                  />
                </Flex>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Flex
        display={["none", "none", "flex"]}
        padding="30px"
        gap="20px"
        background="#F9F9F9"
        flexDirection="column"
      >
        {linksMenu.map((item, _) => (
          <Flex
            key={item.id}
            width="346px"
            height="41px"
            backgroundColor={
              pageType === item.type ? "blue.400" : "transparent"
            }
            borderRadius="5px"
            px="10px"
            alignItems="center"
            justifyContent="space-between"
            cursor="pointer"
            onClick={() => {
              navigate(`/biografia/base-conhecimento/${id}?type=${item.type}`);
            }}
          >
            <Text
              color={pageType === item.type ? "white" : "black"}
              fontWeight="bold"
            >
              {item.title}
            </Text>
            <Icon
              as={RiArrowRightSLine}
              fontSize="18px"
              color={pageType === item.type ? "white" : "black"}
            />
          </Flex>
        ))}
      </Flex>
    </>
  );
}
