export function IconMenuOptionBiography() {
  return (
    <svg
      width={17}
      height={13}
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.126.544a.44.44 0 00-.412-.288H3.97a.44.44 0 00-.412.288l-3.532 9.61a.44.44 0 00.412.59h2.014a.44.44 0 00.413-.288L3.57 8.54h2.543l.705 1.917a.44.44 0 00.412.288h2.014a.44.44 0 00.413-.59L6.126.543zm-.22 5.79a.22.22 0 01-.18.095H3.96a.22.22 0 01-.207-.296l.884-2.404c.069-.19.344-.19.413 0l.883 2.404a.22.22 0 01-.026.202zm8.654-.688a.44.44 0 00-.439.44v.185c-1.616-1.425-4.227-.251-4.219 1.924-.008 2.176 2.604 3.35 4.22 1.924v.186c.022.583.855.582.878 0V6.086a.44.44 0 00-.44-.44zm-.439 2.558a1.672 1.672 0 01-1.67 1.661c-2.215-.092-2.215-3.248 0-3.34.918 0 1.665.745 1.67 1.661v.018z"
        fill="#1E1E1E"
      />
    </svg>
  );
}
