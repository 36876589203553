export function IconHeaderBaseInformation() {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8c8.837 0 16-1.79 16-4s-7.163-4-16-4S0 1.79 0 4s7.163 4 16 4z"
        fill="#683C11"
      />
      <path
        d="M16 10.667c-8.836 0-16-1.791-16-4V16c0 2.21 7.164 4 16 4s16-1.79 16-4V6.667c0 2.209-7.164 4-16 4zM4.667 16a2.001 2.001 0 11.001-4.002A2.001 2.001 0 014.667 16z"
        fill="#683C11"
      />
      <path
        d="M16 22.667c-8.836 0-16-1.791-16-4V28c0 2.21 7.164 4 16 4s16-1.79 16-4v-9.333c0 2.209-7.164 4-16 4zM4.667 28a2.001 2.001 0 11.001-4.002A2.001 2.001 0 014.667 28z"
        fill="#683C11"
      />
    </svg>
  );
}
