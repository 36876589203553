import { Flex, Icon, Text } from "@chakra-ui/react";
import { IconHeaderBookDataHeader } from "../icons/iconHeaderBookDataHeader";

export function BaseInformationHeader() {
  return (
    <Flex display={["none", "none", "flex"]} alignItems="center" gap="15px">
      <Icon as={IconHeaderBookDataHeader} fontSize="18px" color="blue.400" />
      <Text color="#3A3A3A" fontSize="24px" fontWeight="bold">
        Meu livro
      </Text>
    </Flex>
  );
}
