import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { ModalListBookProps } from "./ModalListBook.types";

export function ModalListBook({
  is_open,
  onClose,
  onSelected,
  data,
  ...rest
}: ModalListBookProps) {
  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          onClose();
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="md"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="left"
            style={{
              color: "#181818",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            Biografias
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={5}>
            <Flex flexDirection="column">
              {data.map((item, _) => (
                <Flex
                  key={item.id}
                  height="50px"
                  width="100%"
                  borderRadius="10px"
                  borderWidth={1}
                  borderColor="#DDDDDD"
                  px="10px"
                  mb="5px"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => {
                    onSelected({ id: item.id });
                  }}
                >
                  <Text color="#3A3A3A" fontWeight="bold">
                    {item.title}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
