import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../components/form/input";
import { InputPassword } from "../../../components/form/inputPassword";

import { api } from "../../../service/api";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { IMAGE_LOGIN, LOGO, LOGO_BRANCA } from "../../../assets";

type createFormData = {
  first_name: string;
  surname: string;
  email: string;
  password: string;
  password_confirm: string;
};

const createFormDataSchema = yup.object().shape({
  first_name: yup.string().required("Campo obrigatório."),
  surname: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .required("Ccampo obrigatório.")
    .email("Email não é valido"),
  password: yup
    .string()
    .required("Campo obrigatório.")
    .min(6, "A senha deve ter pelo menos 6 dígitos"),
  password_confirm: yup
    .string()
    .required("Campo obrigatório.")
    .oneOf([yup.ref("password")], "Confirme a senha"),
});

export function RegisterAccount() {
  const navigate = useNavigate();
  const toast = useToast();

  const { isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  if (isAtuhLoading) return <Box />;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      await api.post("account/create", value);

      navigate("/cadastro/sucesso");

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Conta cadastrada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível criar conta.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex w="100%" h="100vh" alignItems="center" justifyContent="center">
      <Flex
        style={{ height: "100vh" }}
        flexDirection="column"
        bg="white"
        w={["100%", "100%", "55%"]}
        paddingLeft={["20px", "20px", "0px"]}
        paddingRight={["20px", "20px", "0px"]}
      >
        <Flex
          style={{ marginTop: "64px" }}
          margin={["auto", "auto", "auto"]}
          marginLeft={["auto", "0px", "70px"]}
        >
          <Image
            src={LOGO}
            style={{ width: "333px", height: "40px" }}
            alt="logo"
          />
        </Flex>

        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(handleSend)}
        >
          <Flex flexDirection="column" style={{ width: "450px" }}>
            <Text style={{ fontWeight: 500, fontSize: 24 }} color="gray.200">
              Crie sua conta
            </Text>
            <Flex flexDirection="column" style={{ marginTop: 26 }}>
              <Input
                label="Nome"
                placeholder="Seu nome"
                error={errors.first_name}
                {...register("first_name")}
              />
              <Box style={{ marginTop: 10 }}>
                <Input
                  label="Sobrenome"
                  placeholder="Seu último nome"
                  error={errors.surname}
                  {...register("surname")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <Input
                  label="Endereço de email"
                  placeholder="seu@email.com"
                  type="email"
                  error={errors.email}
                  {...register("email")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputPassword
                  label="Senha"
                  type="password"
                  style={{ fontSize: 14 }}
                  placeholder="Sua senha"
                  {...register("password")}
                  error={errors.password}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputPassword
                  label="Confirmar senha"
                  type="password"
                  style={{ fontSize: 14 }}
                  placeholder="Confirme sua senha"
                  {...register("password_confirm")}
                  error={errors.password_confirm}
                />
              </Box>

              <Button
                isLoading={isSubmitting}
                type="submit"
                bg="blue.400"
                color="white"
                style={{
                  borderRadius: 10,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "blue.400",
                }}
                mt="6"
                size="lg"
              >
                Criar minha conta
              </Button>

              <Stack
                align="center"
                justifyContent="center"
                direction="row"
                style={{ marginTop: 21 }}
              >
                <Text style={{ fontWeight: 400, fontSize: 16 }} color="#1E1E1E">
                  Já tem conta
                </Text>

                <Link
                  href="/Login"
                  color="blue.400"
                  style={{ fontWeight: 400, fontSize: 16 }}
                >
                  Faça Login
                </Link>
              </Stack>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{ height: "100vh", width: "45%" }}
        display={["none", "none", "flex"]}
        bg="white"
        position="relative"
      >
        <Image
          src={IMAGE_LOGIN}
          style={{ width: "100%", objectFit: "cover" }}
          alt="imagem robo"
        />
        <Flex
          position="absolute"
          top="38%"
          left="30%"
          width="396px"
          height="263px"
        >
          <Image
            src={LOGO_BRANCA}
            style={{ width: "396px", height: "263px" }}
            alt="logo"
          />
        </Flex>
      </Flex>
    </Flex>
  );
}
