import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { BaseInformationDTO } from "../../../../../../dtos/BaseInformationDTO";
import { useEffect } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import { lmWord } from "../../../../../../utils/utils";
import { RiPauseFill, RiPlayMiniFill } from "react-icons/ri";

type Props = {
  data: BaseInformationDTO;
};
export function AudioPlay({ data }: Props) {
  const recorderControls = useVoiceVisualizer();
  const { setPreloadedAudioBlob } = recorderControls;

  useEffect(() => {
    if (data.path) {
      fetch(data.path, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
          "Access-Control-Allow-Private-Network": "true",
        },
        method: "GET",
      })
        .then((res) => res.blob())
        .then((blob) => {
          setPreloadedAudioBlob(blob);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.path]);

  return (
    <Flex gap="20px" px="20px" py="15px" width="100%">
      <Flex flexDirection="column">
        <Text color="#3A3A3A" fontWeight="bold">
          {lmWord(data.title, 20)}
        </Text>
        <Text color="#D7D7D7" fontSize="12px">
          {recorderControls.formattedDuration}
        </Text>
      </Flex>
      <Box width="60%">
        <VoiceVisualizer
          width="100%"
          mainBarColor="#D7D7D7"
          secondaryBarColor="#36A9E1"
          barWidth={10}
          height={40}
          controls={recorderControls}
          isControlPanelShown={false}
        />
      </Box>

      <Flex
        marginLeft="auto"
        gap="10px"
        alignItems="center"
        onClick={() => {
          recorderControls.togglePauseResume();
        }}
      >
        <Text color="#3A3A3A" fontWeight="bold">
          {!recorderControls.isPausedRecordedAudio
            ? "Parar áudio"
            : "Ouvir áudio"}
        </Text>
        <Icon
          fontSize="30px"
          as={
            !recorderControls.isPausedRecordedAudio
              ? RiPauseFill
              : RiPlayMiniFill
          }
          color="#3A3A3A"
        />
      </Flex>
    </Flex>
  );
}
