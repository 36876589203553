import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import { PageLayout, SpecialZoomLevel, Worker } from "@react-pdf-viewer/core";

// Import the main component
import { Viewer, ProgressBar } from "@react-pdf-viewer/core";
import { bookmarkPlugin } from "@react-pdf-viewer/bookmark";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

import { printPlugin, RenderPrintProps } from "@react-pdf-viewer/print";

// Import styles
import "@react-pdf-viewer/print/lib/styles/index.css";

import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

// Import styles
import "@react-pdf-viewer/toolbar/lib/styles/index.css";

import { ModalPagePDFProps } from "./ModalPagePDF.types";
import { Printer } from "lucide-react";

export function ModalPagePDF({
  is_open,
  onClose,
  link_pdf,
  isPrint = true,
  ...rest
}: ModalPagePDFProps) {
  const pageLayout: PageLayout = {
    transformSize: ({ size }) => ({
      height: size.height + 30,
      width: size.width + 30,
    }),
  };

  const bookmarkPluginInstance = bookmarkPlugin();
  const printPluginInstance = printPlugin();
  const toolbarPluginInstance = toolbarPlugin();

  const { Print } = printPluginInstance;

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          onClose();
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="left"
            style={{
              color: "#181818",
              fontSize: 16,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {" "}
            {isPrint && (
              <Print>
                {(props: RenderPrintProps) => (
                  <button
                    style={{
                      backgroundColor: "#357edd",
                      border: "none",
                      borderRadius: "4px",
                      color: "#ffffff",
                      cursor: "pointer",
                      padding: "8px",
                    }}
                    onClick={props.onClick}
                  >
                    <Printer />
                  </button>
                )}
              </Print>
            )}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Flex
              flex={1}
              flexDirection="column"
              width="100%"
              height="97vh"
              margin="auto"
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Box
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "95vh",
                    margin: "auto",
                  }}
                  width={["100%", "100%", "76%"]}
                >
                  <Viewer
                    defaultScale={SpecialZoomLevel.PageWidth}
                    fileUrl={link_pdf}
                    plugins={[
                      toolbarPluginInstance,
                      bookmarkPluginInstance,
                      printPluginInstance,
                    ]}
                    pageLayout={pageLayout}
                    renderLoader={(percentages: number) => (
                      <div style={{ width: "240px" }}>
                        <ProgressBar progress={Math.round(percentages)} />
                      </div>
                    )}
                  />
                </Box>
              </Worker>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
