import { useEffect, useState } from "react";
import { Button, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { BoxLoading } from "../../../../../components/boxLoading";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { api } from "../../../../../service/api";
import { useShowBookData } from "../../hooks/useShowBookData";
import { SummaryDetail } from "../summaryDetail";
import EditorV2 from "../../../../../components/editorV2";
import { ModalPageLoadingAiNext } from "../../../../../components/modalPageLoadingAiNext";
import { Check } from "lucide-react";
import { statusModalPageLoadingAiNext } from "../../../../../components/modalPageLoadingAiNext/ModalPageLoadingAiNext.types";
import { useBiographyContext } from "../../../../../hooks/contexts/biography/biography.context";

type Props = {
  id: string;
  pageType: string;
  title: string;
  titlleButtom: string;
};
export function PageSummaryOrSynopsis({
  id,
  pageType,
  title,
  titlleButtom,
}: Props) {
  const toast = useToast();

  const { getMenuBook } = useBiographyContext();

  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingSendFinalize, setLoadingSendFinalize] = useState(false);
  const [loadingSendUpdate, setLoadingSendUpdate] = useState(false);
  const [isOpenModalStatus, setIsOpenModalStatus] = useState<boolean>(false);
  const [statusModal, setStatusModal] =
    useState<statusModalPageLoadingAiNext>("not_started");

  const { dataList, loading, refetch } = useShowBookData();

  useEffect(() => {
    refetch({ id, categoryName: pageType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageType]);

  async function updateData(content: string) {
    try {
      setLoadingSendUpdate(true);
      await api.put(`book/chapter/update/${dataList.id}`, {
        biography_id: id,
        category_name: pageType,
        title: dataList.title ?? "",
        content,
      });
    } catch (error) {
    } finally {
      setLoadingSendUpdate(false);
    }
  }

  async function sendData() {
    try {
      setLoadingSend(true);
      setStatusModal("processing");
      setIsOpenModalStatus(true);
      await api.post("book/chapter/create", {
        biography_id: id,
        category_name: pageType,
        title,
      });

      setIsOpenModalStatus(false);

      if (pageType === "summary") {
        getMenuBook({ id, category_name: "chapter" });
      }

      refetch({ id, categoryName: pageType });
    } catch (error) {
      setIsOpenModalStatus(false);
      setStatusModal("error");
      setIsOpenModalStatus(true);
    } finally {
      setLoadingSend(false);
    }
  }

  async function onFinalize() {
    try {
      setLoadingSendFinalize(true);
      await api.put(`book/chapter/finalize/${dataList.id}`, {
        type: pageType,
        biography_id: id,
      });
      if (pageType === "summary") {
        getMenuBook({ id, category_name: "chapter" });
      } else {
        window.location.reload();
      }
    } catch (error) {
      toast({
        position: "top-right",
        title: "Finalizar página",
        description: "Não foi possível finalizar página.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingSendFinalize(false);
    }
  }

  async function onRewrite() {
    try {
      setLoadingSend(true);
      await api.put(`book/chapter/rewrite/${dataList.id}`, {
        biography_id: id,
        category_name: pageType,
      });
      refetch({ id, categoryName: pageType });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Finalizar página",
        description: "Não foi possível reescrever página.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingSend(false);
    }
  }

  return (
    <>
      <ModalPageLoadingAiNext
        is_open={isOpenModalStatus}
        title={
          statusModal === "processing"
            ? "A IA está analisando suas informações.."
            : "Não foi possível analisar suas informações.."
        }
        description={
          statusModal === "processing"
            ? "Isso pode levar algum tempo."
            : "Você pode tentar novamente"
        }
        statusModal={statusModal}
        titleBtn="Tentar novamente"
        onRefresh={() => {
          sendData();
        }}
        onClonse={() => {
          setIsOpenModalStatus(false);
          setStatusModal("done");
        }}
      />
      {loading && (
        <Flex height="70vh" width="100%" position="relative">
          <BoxLoading />
        </Flex>
      )}

      {!loading && !dataList.id && (
        <Button
          isLoading={loadingSend}
          type="button"
          bg="blue.400"
          color="white"
          style={{
            borderRadius: 10,
            height: 44,
            fontSize: 16,
            fontWeight: 600,
          }}
          _hover={{
            bg: "blue.400",
          }}
          size="lg"
          cursor="pointer"
          onClick={() => {
            sendData();
          }}
        >
          {titlleButtom}
        </Button>
      )}

      {!loading && dataList.id && pageType !== "summary" && (
        <Flex flexDirection="column">
          <Flex>
            <EditorV2
              isLoading={loadingSendFinalize || loadingSend}
              isLoadingSave={loadingSendUpdate}
              content={dataList.content}
              isRewrite={true}
              onFinalize={() => {
                onFinalize();
              }}
              onRewrite={() => {
                onRewrite();
              }}
              onSave={(text) => {
                updateData(text);
              }}
              urlApi={`book/ia/generete/text/${dataList.id}`}
              dataApi={{
                localtion: "chapter",
                biography_id: id,
              }}
            />
          </Flex>
        </Flex>
      )}
      <Flex flexDirection="column">
        {!loading && dataList.id && pageType === "summary" && (
          <Text mb="20px" fontSize="28px" textAlign="center" fontWeight={600}>
            Sumário
          </Text>
        )}

        {!loading && dataList.id && pageType === "title_ebook" && (
          <Text mb="20px" fontSize="28px" textAlign="center" fontWeight={600}>
            Título do livro
          </Text>
        )}

        {!loading &&
          dataList.id &&
          pageType === "summary" &&
          dataList.summary_detail &&
          dataList.summary_detail.map((item, _) => (
            <SummaryDetail data={item} biography_id={id} />
          ))}

        {!loading &&
          dataList.id &&
          pageType === "summary" &&
          dataList.status !== "complete" && (
            <Flex mt="20px">
              <Button
                isLoading={loadingSendFinalize}
                type="button"
                bg="blue.400"
                color="white"
                style={{
                  borderRadius: 10,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "blue.400",
                }}
                size="lg"
                cursor="pointer"
                onClick={() => {
                  onFinalize();
                }}
                leftIcon={<Icon as={Check} fontSize="18px" color="white" />}
              >
                Finalizar
              </Button>
            </Flex>
          )}
      </Flex>
    </>
  );
}
