import { extendTheme } from "@chakra-ui/react";
import { switchTheme } from "./switch";

export const themeChakra = extendTheme({
  colors: {
    green: {
      100: "#26FFFF",
      500: "#42AD4D",
    },
    blue: {
      100: "#DEEAF6",
      400: "#36A9E1",
      500: "#0577BE",
    },
    gray: {
      50: "#A1A1A1",
      100: "#B6A790",
      200: "#3A3A3A",
    },
  },
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
  styles: {
    global: {
      body: {
        bg: "white",
      },
    },
  },

  components: {
    Switch: switchTheme,
  },
});
