import { Box, Flex } from "@chakra-ui/react";
import React from "react";

type Props = {
  children: React.ReactNode;
};

export function ContainerSmall({ children }: Props) {
  return (
    <Box
      margin="0 auto"
      maxW="1366px"
      width="100%"
      px={["4%"]}
      py="40px"
      justifyContent="center"
    >
      <Flex
        width={["100%", "100%", "1200px"]}
        justifyContent={["center", "center", "flex-start"]}
      >
        {children}
      </Flex>
    </Box>
  );
}
