import { Box, Spinner } from "@chakra-ui/react";

type Props = {
  calcWidth?: string;
  calcHeight?: string;
  borderRadius?: string;
};

export function BoxLoading({
  calcWidth = "0px",
  calcHeight = "0px",
  borderRadius = "10px",
}: Props) {
  return (
    <Box
      height={`100%`}
      width={`100%`}
      marginLeft={`-${calcWidth}`}
      marginTop={`-${calcHeight}`}
      borderRadius={`${borderRadius}`}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      opacity="0.9"
      background="white"
    >
      <Spinner
        color="blue.400"
        emptyColor="white"
        thickness="4px"
        size="xl"
        h={55}
        w={55}
      />
    </Box>
  );
}
