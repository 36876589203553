import {
  Button,
  Flex,
  Icon,
  List,
  ListIcon,
  ListItem,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { RiArrowRightSLine, RiCheckboxCircleFill } from "react-icons/ri";
import { PlanDTO, PlanItems } from "../../../../dtos/PlanDTO";
import { useEffect, useState } from "react";
import { Subscription } from "../../../../dtos/UsersDTO";
import { api } from "../../../../service/api";
import { numberFormart } from "../../../../utils/formatters";
import { formatCurrency } from "../../../../utils/utils";

type Props = {
  typePlan: "free" | "pro";
  type: "month" | "year";
  title: string;
  titleButton: string;
  plans: PlanDTO[];
  subscription?: Subscription;
};

export function CardPlan({
  type,
  typePlan,
  title,
  titleButton,
  plans,
  subscription,
}: Props) {
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const [planItemsData, setPlanItemsData] = useState<PlanItems[]>([]);
  const [planSelected, setPlanSelected] = useState<string>("");
  const [dataPlan, setDataPlan] = useState<PlanDTO[]>([]);

  useEffect(() => {
    setPlanSelected("");
    setPlanItemsData([]);

    if (typePlan === "free" && plans.length > 0) {
      const itemPlan = plans[0].plan_items;
      setPlanItemsData(itemPlan);
    }

    if (typePlan === "pro" && plans.length > 0) {
      const plan = plans.filter((item) => item.type === type);
      setDataPlan(plan);

      const actualPlan = plan[0];
      setPlanSelected(actualPlan.id);
    }
  }, [plans, typePlan, type]);

  useEffect(() => {
    if (planSelected) {
      const planInfo = dataPlan.find((data) => data.id === planSelected);
      if (planInfo) {
        setPlanItemsData(planInfo.plan_items);
      } else {
        setPlanItemsData([]);
      }
    }
  }, [dataPlan, planSelected]);

  async function createSubscription() {
    try {
      if (!planSelected) {
        throw new Error("");
      }

      const data = {
        plan_id: planSelected,
      };
      setLoading(true);
      const response = await api.post("/payments/create/subscription", data);
      toast({
        position: "top-right",
        title: "Assinatura",
        description:
          "Sua assinatura foi iniciada. Você será direcionado para pagamento",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      window.location.replace(response.data.link_pay);
    } catch (error) {
      toast({
        position: "top-right",
        title: "Assinatura",
        description: "Não foi possivel criar assinatura.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex
      width={["100%", "100%", "437px"]}
      minHeight="541px"
      borderRadius="15px"
      borderWidth="1px"
      borderColor="#E5E4E4"
      px="25px"
      py="22px"
      flexDirection="column"
    >
      <Text color="#1E1E1E" fontSize="22px" fontWeight={600}>
        {title}
      </Text>
      {typePlan === "free" ? (
        <Flex
          mt="25px"
          borderWidth={1}
          borderColor="#9D9D9D"
          borderRadius="10px"
          padding="10px"
        >
          <Text fontSize="24px" color="#1E1E1E" fontWeight={500}>
            {plans.length > 0 && numberFormart(plans[0].credits)} créditos / por
            mês
          </Text>
        </Flex>
      ) : (
        <Select
          size="lg"
          mt="25px"
          fontSize="24px"
          color="#1E1E1E"
          fontWeight={500}
          borderColor="#9D9D9D"
          height="56px"
          onChange={(option) => {
            setPlanSelected(option.target.value);
          }}
          value={planSelected}
          placeholder="Selecione o plano"
        >
          {dataPlan.map((item, _) => (
            <option
              value={item.id}
              disabled={subscription && subscription.plan_id === item.id}
              selected={subscription && subscription.plan_id === item.id}
            >
              {numberFormart(item.credits)} créditos /{" "}
              {`${type === "month" ? "por mês" : "por ano"}`}
            </option>
          ))}
        </Select>
      )}

      <List spacing={3} mt="25px" flex={1}>
        {planItemsData.map((item, _) => (
          <ListItem
            key={item.id}
            fontSize="14px"
            paddingBottom="10px"
            borderBottomWidth={1}
            borderBottomColor="#E5E4E4"
          >
            <ListIcon as={RiCheckboxCircleFill} color="#1E1E1E" />
            {item.name}
          </ListItem>
        ))}
      </List>
      {typePlan === "pro" && (
        <Flex mt="25px">
          <Text color="#1E1E1E" fontSize="28px" fontWeight={600}>
            R$ {getPrice(plans, planSelected)}
          </Text>
        </Flex>
      )}
      <Flex mt="25px">
        {subscription && subscription.type === "pro" && typePlan === "free" && (
          <Button
            type="button"
            bg="#1E1E1E"
            color="white"
            width="100%"
            style={{
              borderRadius: 50,
              height: 44,
              fontSize: 16,
              fontWeight: 600,
            }}
            _hover={{
              bg: "#1E1E1E",
            }}
            justifyContent="space-between"
            size="lg"
            isDisabled
            rightIcon={
              <Icon fontSize="18px" as={RiArrowRightSLine} color="white" />
            }
          >
            {titleButton}
          </Button>
        )}

        {typePlan === "pro" && planSelected && (
          <Button
            type="button"
            bg="#1E1E1E"
            color="white"
            width="100%"
            style={{
              borderRadius: 50,
              height: 44,
              fontSize: 16,
              fontWeight: 600,
            }}
            _hover={{
              bg: "#1E1E1E",
            }}
            justifyContent="space-between"
            size="lg"
            rightIcon={
              <Icon fontSize="18px" as={RiArrowRightSLine} color="white" />
            }
            isLoading={loading}
            isDisabled={subscription && subscription.plan_id === planSelected}
            onClick={() => {
              createSubscription();
            }}
          >
            {titleButton}
          </Button>
        )}
      </Flex>
      {typePlan === "pro" && planSelected && (
        <Flex mt="10px">
          <Button
            type="button"
            bg="transparent"
            color="#9D9D9D"
            width="100%"
            borderWidth={1}
            borderColor="#9D9D9D"
            style={{
              borderRadius: 50,
              fontSize: 16,
              fontWeight: 600,
            }}
            _hover={{
              bg: "white",
            }}
            minH={["100%", "100%", "44px"]}
            size="lg"
          >
            Falar com equipe comercial
          </Button>
        </Flex>
      )}
    </Flex>
  );
}

function getPrice(plans: PlanDTO[], id: string) {
  const plan = plans.find((infoPlan) => infoPlan.id === id);
  if (plan) {
    return formatCurrency(plan.unit_amount / 100);
  }

  return 0;
}
