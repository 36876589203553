import {
  FormControl,
  FormLabel,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
  FormErrorMessage,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface Props extends ChakraTextareaProps {
  name: string;
  label?: string;
  size?: "lg" | "md" | "sm" | "xl";
  error?: FieldError;
}

const InputTextAreaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  Props
> = ({ name, label, size = "lg", error, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel color="gray.200" htmlFor={name}>
          {label}
        </FormLabel>
      )}

      <ChakraTextarea
        {...rest}
        id={name}
        name={name}
        borderColor="gray.50"
        borderWidth={1}
        focusBorderColor="gray.100"
        bgColor="transparent"
        color="black"
        variant="filled"
        borderRadius={"10px"}
        height={["200px", "200px", "500px"]}
        _hover={{
          bgColor: "transparent",
        }}
        style={{ fontSize: 14 }}
        _placeholder={{ color: "gray.50" }}
        ref={ref}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const InputTextArea = forwardRef(InputTextAreaBase);
