import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import { PlanDTO } from "../../../dtos/PlanDTO";

export function useListPlan() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<PlanDTO[]>([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get("plan/list");
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
