import { Flex, Icon, Text } from "@chakra-ui/react";
import { RiArrowLeftCircleFill } from "react-icons/ri";

type Props = {
  title: string;
};
export function Title({ title }: Props) {
  return (
    <Flex style={{ alignItems: "center" }}>
      <Icon
        fontSize={["16px", "16px", "24px"]}
        as={RiArrowLeftCircleFill}
        color="#1E1E1E"
      />
      <Text
        style={{
          color: "#1E1E1E",
          fontWeight: 600,
          marginLeft: 13,
        }}
        fontSize={["16px", "16px", "24px"]}
      >
        {title}
      </Text>
    </Flex>
  );
}
