import { Flex, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  icon: any;
};

export function TitleTabBaseInformation({ title, icon }: Props) {
  return (
    <Flex alignItems="center" gap="7px" justifyContent="center">
      {icon}
      <Text>{title}</Text>
    </Flex>
  );
}
